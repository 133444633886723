/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import cn from 'classnames'
import { stringify } from 'query-string'
import { useHistory } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'
import { useQueryParams } from '../../hooks/queryString'

export default function CustomersActionsBar({ view, onViewChange }) {
    const history = useHistory()
    const params = useQueryParams()

    function filterByType(type) {
        history.push(`?${stringify({ ...params, type })}`)
    }

    return (
        <div className="columns">
            <div className="column is-narrow">
                <div className="field has-addons are-small">
                    <p className="control">
                        <button className="button is-small"
                            onClick={() => onViewChange('table')}
                            disabled={view === 'table'}
                            title="Отобразить в виде таблицы">

                            <i className={cn('icon ion-md-grid', css(styles.icon))} />
                        </button>
                    </p>

                    <p className="control">
                        <button className="button is-small"
                            onClick={() => onViewChange('board')}
                            disabled={view === 'board'}
                            title="Отобразить в виде колонок">

                            <i className={cn('icon ion-md-list', css(styles.icon))} />
                        </button>
                    </p>
                </div>
            </div>

            <div className="column">
                <div className="tags is-right">
                    <a onClick={() => filterByType('')}
                        className={cn('tag', { 'light-primary': params.type === '' })}>

                        <i className="icon ion-ios-people" />
                        Все клиенты
                    </a>

                    <a onClick={() => filterByType('active')}
                        className={cn('tag', { 'light-info': params.type === undefined || params.type === 'active' })}>

                        <i className="icon ion-ios-flame" />
                        Активные
                    </a>

                    <a onClick={() => filterByType('rejected')}
                        className={cn('tag', { 'light-danger': params.type === 'rejected' })}>

                        <i className="icon ion-ios-warning" />
                        Отказавшиеся
                    </a>

                    <a onClick={() => filterByType('archived')}
                        className={cn('tag', { 'light-gray': params.type === 'archived' })}>

                        <i className="icon ion-ios-filing" />
                        Архив
                    </a>
                </div>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    icon: {
        transform: 'rotate(90deg) scale(1.5)',
    },
})
