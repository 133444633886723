import React, { useState } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { Form, Formik } from 'formik'
import Layout from '../../components/center/Layout'
import { useQueryParams } from '../../hooks/queryString'
import { DATE_FORMAT } from '../../utils/date'
import StatisticsTabs from '../../components/center/StatisticsTabs'
import Card from '../../components/common/Card'
import Bar from '../../components/dashboard/bar/Bar'
import { useLoad } from '../../hooks/request'
import { HOMEWORK, MARK, MUSTER, SUBJECT_DATE_TIME_LIST } from '../../urls'
import Loader from '../../components/common/Loader'
import Select from '../../components/common/Select'
import Date from '../../components/common/Date'


export default function StatisticsSchool() {
    const history = useHistory()
    const params = useQueryParams()
    const hour = useLoad({ url: SUBJECT_DATE_TIME_LIST })
    const date = params.date || moment().subtract(0, 'months').format(DATE_FORMAT)
    const [hourId, setHourId] = useState(1)
    const muster = useLoad({
        url: MUSTER,
        params: { hour: hourId, date, group_type: 'school' },
    }, [hourId])
    const mark = useLoad({
        url: MARK,
        params: { hour: hourId, date, group_type: 'school' },
    }, [hourId])

    const homework = useLoad({
        url: HOMEWORK,
        params: { hour: hourId, date, group_type: 'school' },
    }, [hourId])

    return (
        <Layout className="has-background-light">
            <StatisticsTabs />
            <Card>
                <div style={{ gap: 10, overflowX: 'scroll', overflowY: 'hidden' }} className="is-flex">
                    <Formik initialValues={{ subject: '', hour: '' }}>
                        <Form style={{ gap: 10 }} className="is-flex">
                            <Select
                                loading={hour.loading}
                                name="hour"
                                onChange={(e) => setHourId(e.target.value)}
                                options={hour.response ? hour.response : []}
                            />
                        </Form>

                    </Formik>


                    <Date
                        date={date}
                        onDateChange={(dates) => {
                            history.push(`?${queryString.stringify({ ...params, ...dates })}`)
                        }} />
                </div>

            </Card>
            <br />
            <Card>
                <div className="title is-5 has-text-centered">
                    Davomatlar statistikasi
                </div>
                {!muster.loading ? (
                    <Bar groups={muster.response ? muster.response.groups : []}
                        absentPercentages={muster.response ? muster.response.absentPercentages : []}
                        absentCounts={muster.response ? muster.response.absentCounts : []} />
                )
                    : <Loader center large />}s
            </Card>
            <br />
            <Card>
                <div style={{ overflowX: 'scroll' }}>
                    <div className="title is-5 has-text-centered">
                        Baholar statistikasi
                    </div>
                    {!mark.loading ? (

                        <table className="table is-striped is-bordered" style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th>Sinflar</th>
                                    <th>2 baho</th>
                                    <th>3 baho</th>
                                    <th>4 baho</th>
                                    <th>5 baho</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    mark.response
                                        ? mark.response.groups.map((elem, index) => (
                                            <tr key={index}>
                                                <td>{elem}</td>
                                                <td>{mark.response.mark2Counts[index]}</td>
                                                <td>{mark.response.mark3Counts[index]}</td>
                                                <td>{mark.response.mark4Counts[index]}</td>
                                                <td>{mark.response.mark5Counts[index]}</td>
                                            </tr>
                                        ))
                                        : null
                                }
                            </tbody>
                        </table>
                    )
                        : <Loader center large />}
                </div>
            </Card>
            <br />
            <Card>
                <div style={{ overflowX: 'scroll' }}>
                    <div className="title is-5 has-text-centered">
                        Uyga vazifa statistikasi
                    </div>
                    {!homework.loading ? (

                        <table className="table is-striped is-bordered" style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th>Sinflar</th>
                                    <th>Bajarilgan</th>
                                    <th>Qoniqarli</th>
                                    <th>Bajarilmagan</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    homework.response
                                        ? homework.response.groups.map((elem, index) => (
                                            <tr key={index}>
                                                <td>{elem}</td>
                                                <td>{homework.response.goodHomeworkCounts[index]}</td>
                                                <td>{homework.response.satisfactoryHomeworkCounts[index]}</td>
                                                <td>{homework.response.notDoneHomeworkCounts[index]}</td>
                                            </tr>
                                        ))
                                        : null
                                }
                            </tbody>
                        </table>


                    )
                        : <Loader center large />}
                </div>
            </Card>
            <br />


            {/*
             <StatisticsAcademySalesChannel
                startDate={startDate}
                endDate={endDate} />

             <StatisticsAcademyCostAdvert
                startDate={startDate}
                endDate={endDate} /><br />

             <StatisticsSalesFunnel
                startDate={startDate}
                endDate={endDate} />
        */}

        </Layout>
    )
}
