export const SIGNIN = '/users/sign-in'
export const SIGNUP = '/users/sign-up'
export const SIGNOUT = '/users/sign-out'
export const USER_SETTINGS = '/users/user_settings'
export const CHANGE_PASSWORD = '/users/change_password'
export const CONFIRM = '/users/confirm'
export const RESET_PASSWORD = '/users/reset_password'
export const RESET_LINK = '/users/reset_link'

export const USERS_LIST = '/users/user'
export const GROUP_TIMETABLE = '/school/group-timetable/{groupId}'

export const REFRESH = '/users/refresh'

export const GROUPS_LIST = '/academy/group/'
export const GROUPS_TIMELINE_SYNC = '/academy/group-sync/'
export const GROUPS_DETAIL = '/academy/group/{id}'
export const GROUPS_ARCHIVE = '/academy/group/archive/{id}'

export const SALES_CHANNEL_LIST = 'academy/sales_channel/'
export const SALES_CHANNEL_DETAIL = 'academy/sales_channel/{id}'
export const SALES_CHANNEL_CHANGE_MAIN = '/academy/sales_channel/change_main/{id}'

export const ROOM_LIST = '/academy/room/'
export const ROOM_DETAIL = '/academy/room/{id}'
export const ROOM_CHANGE_MAIN = '/academy/room/change_main/{id}'

export const STUDENT_LIST = '/academy/student/'
export const STUDENT_DETAIL = '/academy/student/{id}'

export const CUSTOMER_LIST = '/academy/customer/'
export const CUSTOMER_DETAIL = '/academy/customer/{id}'
export const CUSTOMER_MOVE = '/academy/customer/{id}/move'

export const STATUS_LIST = '/academy/status/'
export const STATUS_DETAIL = '/academy/status/{id}'
export const STATUS_MOVE = '/academy/status/{id}/move'

export const TRANSACTION_LIST = '/finance/transaction/'
export const TRANSACTION_DETAIL = '/finance/transaction/{id}'
export const TRANSACTION_CATEGORY_LIST = '/finance/transaction-category/'
export const TRANSACTION_CATEGORY_DETAIL = '/finance/transaction-category/{id}'
export const TRANSACTION_CATEGORY_CHANGE_MAIN = '/finance/transaction-category/change_main/{id}'
export const TRANSACTION_TRANSFER = '/finance/transaction-transfer/{id}'

export const WALLET_LIST = '/finance/wallet/'
export const WALLET_SIMPLE_LIST = '/finance/wallet-simple-list/'
export const WALLET_DETAIL = '/finance/wallet/{id}'
export const WALLET_CHANGE_MAIN = '/finance/wallet/change_main/{id}'

export const EMPLOYEE_LIST = '/staff/employee/'
export const EMPLOYEE_DETAIL = '/staff/employee/{id}'

export const SCHOOL_STUDENT_LIST = '/school/student'
export const SCHOOL_STUDENT_DETAIL = '/school/student/{studentId}'
export const SUBJECT_LIST = '/school/subject'
export const SUBJECT_DETAIL = '/school/subject/{id}'
export const ASSESSMENT_LIST = '/school/assessment'
export const TIMETABLE_LIST = '/school/timetable'
export const TIMETABLE_DETAIL = '/school/timetable/{id}'
export const TIMETABLE_SUBJECT_LIST = '/school/timetable_subject'
export const TIMETABLE_SUBJECT_DETAIL = '/school/timetable_subject/{id}'
export const ASSESSMENT_DETAIL = '/school/assessment/{id}'
export const ASSESSMENT_ITEM_LIST = '/school/assessment-item'
export const ASSESSMENT_UPDATE = '/school/assessment-update/{id}'
export const ASSESSMENT_ITEM_DETAIL = '/school/assessment-item/{id}'
export const SCHOOL_GROUP_LIST = '/school/group'
export const SCHOOL_GROUP_DETAIL = '/school/group/{id}'
export const INVOICE_LIST = '/finance/invoice'
export const INVOICE_DETAIL = '/finance/invoice/{id}'

export const SUBJECT_DATE_TIME_LIST = '/school/subject-date-time'
export const SUBJECT_DATE_TIME_DETAIL = '/school/subject_date_time/{id}'

export const EMPLOYEE_SIMPLE_LIST = '/staff/employee-simple-list/'
export const EMPLOYEE_SALARY = '/staff/employee-salary/{id}'
export const EMPLOYEE_SALARY_HISTORY = '/staff/employee-salary/{id}/history'
export const EMPLOYEE_INVITATION = '/staff/employee/{id}/invite'
export const EMPLOYEE_INVITATION_SIGNUP = '/staff/employee-sign-up/{code}'

export const PAYMENT_STUDENT = '/finance/student_payment/{id}'
export const SALARY_LIST = 'finance/salary/{employee_id}'

export const TASK_LIST = 'task/task/'
export const TASK_DETAIL = 'task/task/{id}'

export const GENERAL = 'core/general/'

export const STATISTICS_SALES_CHANNEL = 'stats/sales_channel'
export const STATISTICS_SALE = 'stats/sale'
export const STATISTICS_OUTCOME = 'stats/outcome'
export const STATISTICS_FINANCE = 'stats/finance'
export const STATISTICS_COST_ADVERT = 'stats/cost_advert'
export const STATISTICS_SALES_FUNNEL = 'stats/sales_funnel'
export const MUSTER = 'stats/muster'
export const MARK = 'stats/mark'
export const HOMEWORK = 'stats/homework'
