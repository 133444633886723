export const positions = {
    admin: 'Admin / Direktor',
    accountant: 'Buxgalter',
    marketing: "Sotish bo'yicha menejer",
    teacher: "O'qituvchi",
}
export const months = {
    january: 'Yanvar',
    february: 'Fevral',
    march: 'Mart',
    april: 'Aprel',
    may: 'May',
    june: 'Iyun',
    july: 'Iyul',
    august: 'Avgust',
    september: 'Sentabr',
    october: 'Oktabr',
    november: 'Noyabr',
    december: 'Dekabr',
}

export const statuses = {
    canceled: 'To\'langan',
    complete: 'To\'lanmagan',
}
export const groups = {
    course: 'Kurs',
    school: 'Maktab',
}


export const tests = {
    good: 'Bajarilgan',
    satisfactory: 'Qoniqarli',
    not_done: 'Bajarilmagan',
}
export const marks = {
    2: '2',
    3: '3',
    4: '4',
    5: '5',
}

export const classes = {
    admin: 'is-success',
    accountant: 'is-danger',
    marketing: 'is-primary',
    teacher: 'is-warning',
}

export function position(name) {
    return positions[name]
}

export function status(name) {
    return statuses[name]
}

export function month(name) {
    return months[name]
}

export function group(name) {
    return groups[name]
}

export function test(name) {
    return tests[name]
}

export function positionClass(name) {
    return classes[name]
}

export function mark(name) {
    return marks[name]
}
