import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React from 'react'
import { isEmpty } from 'lodash'
import { useModal } from '../../hooks/modal'
import Button from "../common/Button";
import TimeTableSubjectCreate from "./TimeTableSubjectCreate";
import { useLoad } from "../../hooks/request";
import { TIMETABLE_SUBJECT_LIST } from "../../urls";
import { checkPermission as cp } from "../../utils/auth";
import TimeTableSubjectItem from "./TimeTableSubjectItem";


export default function WeekDay({ assessment, classId }) {
    const timetableSubject = useLoad({ url: TIMETABLE_SUBJECT_LIST, params: { timetable: assessment?.id } })
    const timetableSubjectItem = timetableSubject.response ? timetableSubject.response.results : []

    const [showCreateModal, hideCreateModal] = useModal(
        <TimeTableSubjectCreate
            timetableId={assessment?.id}
            classId={classId}
            dayId={assessment.day?.id}
            assessment={assessment}
            onCancel={() => hideCreateModal()}
            onSuccess={() => {
                timetableSubject.request()
                hideCreateModal()
            }}/>,
    )

    return (
        <div className="column is-4-widescreen is-6-desktop is-12-tablet">
            <div className={cn(css(styles.height), 'box')}>
                <div className="columns">
                    <div style={{ marginTop: '-1.5rem' }} className="column">

                        <div className="column is-flex is-justify-content-center">
                            <div
                                className={cn('icon has-text-grey-light', css(styles.icon))}>  {assessment.day.name}

                                {cp('staff.change_employee') ? (
                                    <Button
                                        // text="Qo'shish"
                                        onClick={showCreateModal}
                                        icon="ion-md-add"
                                        className="is-link is-outlined is-small ml-2"/>
                                ) : null}
                            </div>

                        </div>
                        {!isEmpty(timetableSubjectItem) && timetableSubjectItem ? timetableSubjectItem.map((item, index) => (
                            <TimeTableSubjectItem
                                assessment={assessment}
                                timetableSubject={timetableSubject}
                                item={item}
                                index={index}
                                onDelete={timetableSubject.request}/>
                        )) : (
                            <div ref={assessment.ref} className="has-text-grey-light is-italic has-text-centered">
                                {!assessment.hasMore && !assessment.loading && assessment.length !== 0 ? 'Jadvalga darslar qo\'shilmagan' : ''}
                            </div>
                        )}


                    </div>
                    <div className={cn('is-flex is-justify-content-right', css(styles.buttonAction))}>

                    </div>
                </div>
            </div>
        </div>
    )
}


const styles = StyleSheet.create({
    icon: {
        width: '100%',
        fontSize: '2em',
        margin: '2rem 1rem',
    },
    height: {
        height: '100%',
    },
    buttonAction: {
        '@media (max-width: 500px)': {
            display: 'flex',
            marginTop: 10,
            justifyContent: 'center',
        },
    },
})
