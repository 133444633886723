import React from 'react'
import { format } from '../../utils/number'
import { getDateTime } from '../../utils/date'

export default function StudentInvoiceItem({ item }) {
    return (
        <tr>
            <td>
                <span className="is-capitalized">{item.forMonth}</span>
            </td>
            <td>
                <span className="is-capitalized">{item.group.name}</span>
            </td>

            <td>
                <span className="has-text-link">{format(item.fee)} so'm </span>
            </td>

            <td>
                <span>{getDateTime(item.createdAt)}</span>
            </td>
            {item.status === 'canceled' ? (
                <td>
                    <span className="tag is-success is-medium is-light button">
                        To'langan
                    </span>
                </td>
            ) : null}
            {item.status === 'complete' ? (
                <td>
                    <span className="tag is-danger is-medium is-light button">
                        To'lanmagan
                    </span>
                </td>
            ) : null}

        </tr>
    )
}
