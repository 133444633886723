import React from 'react'
import { TIMETABLE_SUBJECT_DETAIL } from '../../urls'
import { usePutRequest } from '../../hooks/request'
import ServerError from '../common/ServerError'
import TimeTableSubjectForm from './TimeTableSubjectForm'

export default function TimeTableSubjectUpdate({ onCancel, onUpdate, employee, timetableId }) {
    const url = TIMETABLE_SUBJECT_DETAIL.replace('{id}', employee.id)
    const employeeUpdate = usePutRequest({ url })

    async function onSubmit(data, actions) {
        const { success } = await employeeUpdate.request({
            data: { ...data, subject: data.subject.name, timetable: timetableId },
        })

        if (success) {
            actions.resetForm()
            onUpdate()
        }
    }


    return (
        <div>
            <b>Fanni tahrirlash</b><br /><br />
            <ServerError error={employeeUpdate.error} />
            <TimeTableSubjectForm
                initialValues={employee}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={employeeUpdate.loading} />
        </div>
    )
}
