import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import { useHistory } from 'react-router-dom'
import NotFoundSvg from '../static/NotFoundSvg'

export default function NotFound() {
    const history = useHistory()
    return (
        <div className={css(styles.parent)}>
            <div className={css(styles.svgDiv)}>
                <NotFoundSvg />
            </div>
            <h2 className={css(styles.title)}>
                Topilmadi
            </h2>
            <blockquote className={css(styles.description)}>
                Voy! Siz yomon havolaga amal qilganga o'xshaysiz. Agar bu bizda muammo deb hisoblasangiz, iltimos, bizga
                ayting
            </blockquote>
            <a href="/">
                <button onClick={() => {
                    history.goBack()
                }} className={css(styles.btn)}>
                    Orqaga qaytish
                </button>
            </a>
        </div>
    )
}

const styles = StyleSheet.create({
    parent: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '15px',
        padding: '3%',
    },
    svgDiv: {
        width: '50%',
    },
    title: {
        fontSize: 32,
        lineHeight: '39px',
    },
    description: {
        fontSize: 20,
        lineHeight: '23px',
    },
    btn: {
        minWidth: 140,
        padding: 14,
        background: '#00ab55',
        borderRadius: 6,
        color: 'white',
        border: 'none',
        cursor: 'pointer',
    },
})
