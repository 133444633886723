import React from 'react'

export default function CustomerGroups({ customer }) {
    return (
        <p className="has-text-grey">
            {customer.students.length >= 2 ? (
                `${customer.students[0].group.name}, ${customer.students[1].group.name}`
            ) : null}

            {customer.students.length > 2 ? ', ...' : ''}
            {customer.students.length === 1 ? customer.students[0].group.name : ''}
        </p>
    )
}
