import React from 'react'
import { SCHOOL_GROUP_DETAIL } from '../../urls'
import { usePutRequest } from '../../hooks/request'
import ServerError from '../common/ServerError'
import GroupForm from './GroupForm'

export default function StudentUpdate({ onCancel, onSuccess, employee }) {
    const url = SCHOOL_GROUP_DETAIL.replace('{id}', employee.id)
    const employeeUpdate = usePutRequest({ url })

    async function onSubmit(data, actions) {
        const { success } = await employeeUpdate.request({
            data: {
                ...data,
                leadTeacher: data.leadTeacher.id,
            },
        })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }


    return (
        <div>
            <b>Sinf yoki kursni o'zgartish</b><br /><br />
            <ServerError error={employeeUpdate.error} />
            <GroupForm
                initialValues={employee}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={employeeUpdate.loading} />
        </div>
    )
}
