import React from 'react'
import { usePostRequest } from '../../hooks/request'
import { SCHOOL_GROUP_LIST } from '../../urls'
import ServerError from '../common/ServerError'
import GroupForm from './GroupForm'

export default function GroupCreate({ onCancel, onSuccess }) {
    const groupCreate = usePostRequest({ url: SCHOOL_GROUP_LIST })

    async function onSubmit(data, actions) {
        const { success } = await groupCreate.request({
            data: {
                ...data,
                leadTeacher: data.leadTeacher.id,
            },
        })
        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }


    return (
        <div>
            <b>Yangi sinf yoki kurs qo'shish</b><br /><br />
            <ServerError error={groupCreate.error} />
            <GroupForm onSubmit={onSubmit} onCancel={onCancel} loading={groupCreate.loading} />
        </div>
    )
}
