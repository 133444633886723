import React from 'react'
import { Formik } from 'formik'
import find from 'lodash/find'
import map from 'lodash/map'
import Select from '../common/Select'
import { required } from '../../utils/validators'
import Input from '../common/Input'
import Button from '../common/Button'
import { useLoad } from '../../hooks/request'
import { GROUPS_LIST } from '../../urls'

export default function CustomerInfoGroupsForm({
    initValues = {},
    onSubmit,
    onCancel,
    customer,
    loading,
}) {
    const initialValues = { price: '', ...initValues, group: initValues.group ? initValues.group.id : '' }
    const groups = useLoad({ url: GROUPS_LIST })
    const groupsIds = map(customer.students, 'group.id')
    const groupOptions = groups.response ? groups.response.results.filter(
        (group) => !groupsIds.includes(group.id) || (initValues.group && initValues.group.id === group.id),
    ) : []

    function onGroupChange(groupId, values, setFieldValue) {
        const group = find(groups.response.results, { id: parseInt(groupId, 10) })
        setFieldValue('group', groupId)
        if (values.price === '') {
            setFieldValue('price', group ? group.price : '')
        }
    }

    return (
        <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
            {({ setFieldValue, values, handleSubmit }) => (
                <tr>
                    <td>
                        <Select
                            empty
                            name="group"
                            className="is-small"
                            options={groupOptions}
                            validate={required}
                            onChange={({ target }) => onGroupChange(target.value, values, setFieldValue)}
                            loading={groups.loading} />
                    </td>

                    <td>
                        <Input
                            name="price"
                            className="is-small"
                            placeholder="Стоимость месячного абонемента"
                            disabled={loading}
                            validate={required}
                            type="number" />
                    </td>

                    <td>
                        <Button
                            loading={loading}
                            onClick={handleSubmit}
                            icon="ion-md-checkmark"
                            className="is-success is-small" /> &nbsp;

                        <Button
                            icon="ion-md-close"
                            disabled={loading}
                            onClick={onCancel}
                            className="is-small" />
                    </td>
                </tr>
            )}
        </Formik>
    )
}
