import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { checkPermission as cp } from '../../utils/auth'

export default function TimeTableTabs() {
    const { path } = useRouteMatch()

    return (
        <div className="tabs">
            <ul>
                {cp('academy.view_customer') ? (
                    <li className={cn({ 'is-active': path.startsWith('/timetable/school') })}>
                        <NavLink to="/timetable/school">Maktab jadvali</NavLink>
                    </li>
                ) : null}

                {cp('academy.view_group') ? (
                    <li className={cn({ 'is-active': path.startsWith('/timetable/academy') })}>
                        <NavLink to="/timetable/academy">Kurs jadvali</NavLink>
                    </li>
                ) : null}
            </ul>
        </div>
    )
}
