import React from 'react'
import { usePostRequest } from '../../hooks/request'
import ServerError from '../common/ServerError'
import { SUBJECT_DATE_TIME_LIST } from '../../urls'
import HourForm from './HourForm'

export default function HourCreate({ onCancel, onSuccess }) {
    const studentCreate = usePostRequest({ url: SUBJECT_DATE_TIME_LIST })

    async function onSubmit(data, actions) {
        const { success } = await studentCreate.request({
            data,
        })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <b>Добавить нового ученика</b><br /><br />
            <ServerError error={studentCreate.error} />
            <HourForm onSubmit={onSubmit} onCancel={onCancel} loading={studentCreate.loading} />
        </div>
    )
}
