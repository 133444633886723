import React from 'react'
import { usePostRequest } from '../../hooks/request'
import { CUSTOMER_LIST } from '../../urls'
import { integersOnly } from '../../utils/number'
import CustomerForm from './CustomerForm'

export default function CustomerCreate({ state, onCancel, onSuccess, initialValues }) {
    const customerCreate = usePostRequest({ url: CUSTOMER_LIST })

    async function onSubmit(values, actions) {
        let phoneSecond = values.phoneSecond ? integersOnly(values.phoneSecond) : null
        phoneSecond = phoneSecond === integersOnly(values.phone) ? null : phoneSecond
        const data = {
            ...values,
            state,
            phone: integersOnly(values.phone),
            phoneSecond,
        }
        const customer = await customerCreate.request({ data })
        actions.resetForm()
        onSuccess(customer.response)
    }

    return (
        <div>
            <b>Добавить нового студента</b><br /><br />

            <CustomerForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                values={initialValues}
                loading={customerCreate.loading} />
        </div>
    )
}
