import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { checkPermission as cp } from '../../utils/auth'

export default function FinanceTabs() {
    const { path } = useRouteMatch()

    return (
        <div className="tabs">
            <ul>
                {/*    {cp('finance.view_wallet') ? (
                    <li className={cn({ 'is-active': path.startsWith('/finance/wallet') })}>
                        <NavLink to="/finance/wallet">Hisoblar</NavLink>
                    </li>
                ) : null}
*/}
                {cp('academy.view_group') ? (
                    <li className={cn({ 'is-active': path.startsWith('/finance/payment/canceled') })}>
                        <NavLink to="/finance/payment/canceled">To'lovlar</NavLink>
                    </li>
                ) : null}
                {cp('academy.view_group') ? (
                    <li className={cn({ 'is-active': path.startsWith('/finance/payment/complete') })}>
                        <NavLink to="/finance/payment/complete">Qarzdorlar</NavLink>
                    </li>
                ) : null}
            </ul>
        </div>
    )
}
