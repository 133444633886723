import React from 'react'
import { usePutRequest } from '../../hooks/request'
import { WALLET_DETAIL } from '../../urls'
import WalletForm from './WalletForm'

export default function WalletUpdate({ values, wallet, onSuccess, onCancel }) {
    const url = WALLET_DETAIL.replace('{id}', wallet.id)
    const walletUpdate = usePutRequest({ url })

    async function onSubmit(data) {
        await walletUpdate.request({ data: { ...data, wallet } })
        onSuccess()
    }

    return (
        <div>
            <b>Изменить счёт</b><br /><br />

            <WalletForm
                onSubmit={onSubmit}
                loading={walletUpdate.loading}
                onCancel={onCancel}
                values={{ ...values, name: wallet.name, icon: wallet.icon }} />
        </div>
    )
}
