import React from 'react'
import { ROOM_LIST } from '../../urls'
import { usePostRequest } from '../../hooks/request'
import RoomForm from './RoomForm'

export default function RoomCreate({ onSuccess, onCancel }) {
    const roomCreate = usePostRequest({ url: ROOM_LIST })

    async function onSubmit(data, actions) {
        await roomCreate.request({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <RoomForm loading={roomCreate.loading} onCancel={onCancel} onSuccess={onSuccess} onSubmit={onSubmit} />
        </div>
    )
}
