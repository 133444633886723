import React from 'react'
import { usePostRequest } from '../../hooks/request'
import { TASK_LIST } from '../../urls'
import TaskForm from './TaskForm'

export default function EmployeeCreate({ onCancel, onSuccess }) {
    const taskCreate = usePostRequest({ url: TASK_LIST })

    async function onSubmit(data, actions) {
        await taskCreate.request({
            data: {
                ...data,
                dueTime: data.dueTime ? data.dueTime : null,
            },
        })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Yangi vazifa qo'shing</b><br /><br />

            <TaskForm
                loading={taskCreate.loading}
                onSubmit={onSubmit}
                onCancel={onCancel} />
        </div>
    )
}
