import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { format } from '../../utils/number'
import { useModal } from '../../hooks/modal'
import Button from '../common/Button'
import { useDeleteRequest } from '../../hooks/request'
import { SCHOOL_GROUP_DETAIL } from '../../urls'
import GroupUpdate from './GroupUpdate'
import { useMessage } from '../../hooks/message'
import { checkPermission as cp } from '../../utils/auth'

export default function CourseGroupItem({ wallet, groups, onDelete }) {
    const classDelete = useDeleteRequest({ url: SCHOOL_GROUP_DETAIL.replace('{id}', wallet.id) })

    const [showUpdateModal, hideUpdateModal] = useModal(
        <GroupUpdate
            employee={wallet}
            onSuccess={() => {
                groups.request()
                hideUpdateModal()
            }} onCancel={() => hideUpdateModal()} />,
    )
    const [showMessage] = useMessage()

    async function deleteGroup() {
        if (global.confirm('Haqiqatan ham oʻchirib tashlamoqchimisiz?')) {
            const { error } = await classDelete.request()
            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }
            onDelete()
        }
    }

    return (
        <div className="column is-4-widescreen is-6-desktop is-12-tablet">
            <div className={cn(css(styles.height), 'box')}>
                <div className="columns">
                    <div style={{ marginTop: '-1.5rem' }} className="column">
                        <Link to={`/class/${wallet.id}`}>
                            <div className="column is-flex is-justify-content-center">
                                <div className={cn('icon has-text-grey-light', css(styles.icon))}>{wallet.name}</div>
                            </div>
                        </Link>
                        <b>{'Kurs rahbari'
                            || <span className="is-italic has-text-grey">ko'rsatilmagan</span>}: {wallet.teacher.name}
                        </b>
                        <p>
                            O'quvchilar soni: &nbsp;
                            <span className="has-text-info">
                                {format(wallet.studentCount || 0)} ta o'quvchi
                            </span>
                        </p>

                        {cp('staff.delete_employee') ? (
                            <div className={cn('is-flex is-justify-content-right', css(styles.buttonAction))}>
                                <Button
                                    onClick={showUpdateModal}
                                    icon="ion-md-create"
                                    className="is-success is-outlined mb-2" /> &nbsp; <br />

                                <Button
                                    onClick={deleteGroup}
                                    icon="ion-md-trash"
                                    className="is-danger is-outlined" />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}


const styles = StyleSheet.create({
    icon: {
        width: '100%',
        fontSize: '1.5em',
        margin: '2rem 1rem',
    },
    height: {
        height: '100%',
    },
    buttonAction: {
        '@media (max-width: 500px)': {
            display: 'flex',
            marginTop: 10,
            justifyContent: 'center',
        },
    },
})
