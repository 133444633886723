import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../common/Button'
import { useModal } from '../../hooks/modal'
import { useDeleteRequest } from '../../hooks/request'
import { ASSESSMENT_DETAIL } from '../../urls'
import { useMessage } from '../../hooks/message'
import AssessmentUpdate from './AssessmentUpdate'


export default function LessonItem({ onDelete, assessment, classId, onUpdate }) {
    const employeeDelete = useDeleteRequest({ url: ASSESSMENT_DETAIL.replace('{id}', assessment.id) })

    const [showUpdateModal, hideUpdateModal] = useModal(
        <AssessmentUpdate classId={classId} employee={assessment} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    const [showMessage] = useMessage()

    async function deleteGroup() {
        if (global.confirm('Haqiqatan ham oʻchirib tashlamoqchimisiz?')) {
            const { error } = await employeeDelete.request()
            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }
            onDelete()
        }
    }


    return (
        <div className="column is-4-widescreen is-6-desktop is-12-tablet">
            <div className={cn(css(styles.height), 'box')}>
                <div className="columns">
                    <div style={{ marginTop: '-1.5rem' }} className="column">
                        <Link to={`/class/${classId}/assessment/${assessment.id}`}>
                            <div className="column is-flex is-justify-content-center">
                                <div
                                    className={cn('icon has-text-grey-light', css(styles.icon))}>  {assessment.subject.name}
                                </div>
                            </div>
                        </Link>
                        <b>{'O\'qituvchi'
                            || <span className="is-italic has-text-grey">ko'rsatilmagan</span>}: {assessment.teacher.name}
                        </b>
                        <p>
                            Soati va vaqti: &nbsp;
                            <span className="has-text-info">
                                {assessment.hour.name}
                            </span>
                        </p>

                        <div className={cn('is-flex is-justify-content-right', css(styles.buttonAction))}>
                            <Button
                                onClick={showUpdateModal}
                                icon="ion-md-create"
                                className="is-success is-outlined mb-2" /> &nbsp; <br />

                            <Button
                                onClick={deleteGroup}
                                icon="ion-md-trash"
                                className="is-danger is-outlined" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const styles = StyleSheet.create({
    icon: {
        width: '100%',
        fontSize: '2em',
        margin: '2rem 1rem',
    },
    height: {
        height: '100%',
    },
    buttonAction: {
        '@media (max-width: 500px)': {
            display: 'flex',
            marginTop: 10,
            justifyContent: 'center',
        },
    },
})
