import React from 'react'
import { getDateTime } from '../../utils/date'
import Button from '../common/Button'
import CustomerInfoGroups from './CustomerInfoGroups'

export default function CustomerInfo({ customer, onCancel, onUpdate, onDelete, onChange }) {
    return (
        <div>
            <b>Информация о студенте: {customer.name}</b><br /><br />

            <table className="table is-striped is-fullwidth">
                <tbody>
                    <tr>
                        <td>Ф.И.О:</td>
                        <td>{customer.name}</td>
                    </tr>

                    <tr>
                        <td>Номер телефона:</td>
                        <td>{customer.phone} {customer.phoneSecond ? <span>, {customer.phoneSecond}</span> : null}</td>
                    </tr>

                    <tr>
                        <td>Канал продаж:</td>
                        <td>{customer.advert.name}</td>
                    </tr>

                    <tr>
                        <td>Менеджер продаж:</td>
                        <td>{customer.seller.name}</td>
                    </tr>

                    <tr>
                        <td>Дата добавления:</td>
                        <td>{getDateTime(customer.createdAt)}</td>
                    </tr>

                    <tr>
                        <td>Примечание:</td>
                        <td>{customer.description}</td>
                    </tr>
                </tbody>
            </table>

            <CustomerInfoGroups onUpdate={onUpdate} onDelete={onDelete} customer={customer} />

            <Button
                onClick={onCancel}
                icon="ion-md-close"
                text="Закрыть"
                className="is-danger" /> &nbsp;

            <Button
                text="Изменить"
                onClick={onChange}
                icon="ion-md-create"
                className="is-primary" />
        </div>
    )
}
