import React from 'react'
import { ROOM_DETAIL } from '../../urls'
import { usePutRequest } from '../../hooks/request'
import RoomForm from './RoomForm'

export default function RoomUpdate({ onSuccess, onCancel, room }) {
    const roomUpdate = usePutRequest({ url: ROOM_DETAIL.replace('{id}', room.id) })

    async function onSubmit(data, actions) {
        await roomUpdate.request({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <RoomForm
                loading={roomUpdate.loading}
                onCancel={onCancel}
                initialValues={room}
                onSuccess={onSuccess}
                onSubmit={onSubmit} />
        </div>
    )
}
