import React from 'react'
import { usePostRequest } from '../../hooks/request'
import ServerError from '../common/ServerError'
import { TIMETABLE_SUBJECT_LIST } from '../../urls'
import TimeTableSubjectForm from './TimeTableSubjectForm'

export default function TimeTableSubjectCreate({ onCancel, onSuccess, timetableId }) {
    const studentCreate = usePostRequest({ url: TIMETABLE_SUBJECT_LIST })

    async function onSubmit(data, actions) {
        const { success } = await studentCreate.request({
            data: { ...data, subject: data.subject.name, timetable: timetableId },
        })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <b>Jadvalga fan qo'shish</b><br /><br />
            <ServerError error={studentCreate.error} />
            <TimeTableSubjectForm onSubmit={onSubmit} onCancel={onCancel} loading={studentCreate.loading} />
        </div>
    )
}
