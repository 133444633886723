import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { NavLink, useRouteMatch } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { checkPermission as cp } from '../../utils/auth'
import { getEnv } from '../../utils/environment'
import { useLoad } from '../../hooks/request'
import { GENERAL } from '../../urls'
import AccountPanel from './AccountPanel'


export default function Layout({ children, padding = true, className }) {
    const { path } = useRouteMatch()
    const [menu, setMenu] = useState(false)
    const general = useLoad({ url: GENERAL })
    const assign = JSON.parse(localStorage.getItem('user'))

    const [time, setTime] = useState(new Date())

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date())
        }, 1000)

        return () => clearInterval(intervalId) // Cleanup the interval on component unmount
    }, [])

    const today = new Date()


    return (
        <div>
            <div className={css(
                styles.sidebar,
                menu ? styles.desktopSidebar : styles.mobileSidebar,
                getEnv() === 'development' ? styles.developmentSidebar : null,
                getEnv() === 'staging' ? styles.stagingSidebar : null,
            )}>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                {/* <img src={logo} className={cn(css(styles.logo), 'is-black')} alt="CRM Neo" /> */}
                <AccountPanel />

                <i onClick={() => setMenu(false)} className={cn(
                    'icon ion-md-arrow-back is-size-4',
                    css(styles.hiderIcon),
                )} />

                <p className={css(styles.menuHeading)}>
                    Menyu
                </p>

                <ul className={css(styles.menu)}>

                    {cp('academy.view_group') || cp('academy.view_advert') || cp('academy.view_customers') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink to="/school" className={css(
                                styles.link,
                                path.startsWith('/school') || path.startsWith('/class') ? styles.active : null,
                            )}>
                                <i className={cn('icon ion-md-school', css(styles.icon))} />

                                Maktab
                            </NavLink>
                        </li>
                    ) : null}
                    {cp('academy.view_group') || cp('academy.view_advert') || cp('academy.view_customers') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink to="/academy/group" className={cn(css(styles.link),
                                { [css(styles.active)]: path.startsWith('/academy') })}>
                                <i className={cn('icon ion-md-apps', css(styles.icon))} />
                                Akademiya
                            </NavLink>
                        </li>
                    ) : null}


                    {cp('staff.view_employee') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink to="/staff/employee" className={css(
                                styles.link,
                                path.startsWith('/staff/employee') ? styles.active : null,
                            )}>
                                <i className={cn('icon ion-md-contacts', css(styles.icon))} />
                                Xodimlar
                            </NavLink>
                        </li>
                    ) : null}


                    {cp('academy.view_group') || cp('academy.view_advert') || cp('academy.view_customers') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink to="/students" className={css(
                                styles.link,
                                path.startsWith('/students') ? styles.active : null,
                            )}>
                                <i className={cn('icon ion-md-person', css(styles.icon))} />
                                O'quvchilar
                            </NavLink>
                        </li>
                    ) : null}

                    {cp('academy.view_group') || cp('academy.view_advert') || cp('academy.view_customers') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink to="/timetable/school" className={css(
                                styles.link,
                                path.startsWith('/timetable') ? (
                                    styles.active
                                ) : null,
                            )}>
                                <i className={cn('icon ion-md-calendar', css(styles.icon))} />
                                Dars jadvali
                            </NavLink>
                        </li>
                    ) : null}

                    {cp('academy.view_advert_statistics') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink to="/statistics/school" className={css(
                                styles.link,
                                path.startsWith('/statistics') ? (
                                    styles.active
                                ) : null,
                            )}>
                                <i className={cn('icon ion-md-stats', css(styles.icon))} />
                                Statistika
                            </NavLink>
                        </li>
                    ) : null}

                    {cp('finance.view_wallet') || cp('finance.view_transactioncategory') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink className={css(styles.link,
                                path.startsWith('/finance') ? styles.active : null)} to="/finance/payment/canceled">
                                <i className={cn('icon ion-md-wallet', css(styles.icon))} />
                                Moliya
                            </NavLink>
                        </li>
                    ) : null}
                    {cp('task.view_task') ? (
                        <li onClick={() => setMenu(false)}>
                            <NavLink to={`/task/?assigned=${assign.id}&status=waiting`} className={css(
                                styles.link,
                                path.startsWith('/task') ? styles.active : null,
                            )}>
                                <i className={cn('icon ion-md-checkbox', css(styles.icon))} />
                                Topshiriqlar &nbsp;

                                <span className="tag is-success is-rounded">
                                    {general.response ? general.response.tasksCount : 0}
                                </span>
                            </NavLink>
                        </li>
                    ) : null}


                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/settings/academy" className={cn(css(styles.link),
                            { [css(styles.active)]: path.startsWith('/settings') })}>
                            <i className={cn('icon ion-md-settings', css(styles.icon))} />
                            Sozlamalar
                        </NavLink>
                    </li>
                    <br />
                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/" className={cn(css(styles.link),
                            { [css(styles.active)]: path.startsWith('/') })}>
                            <i className={cn('icon ion-md-time', css(styles.icon))} />
                            {time.toLocaleTimeString()}
                        </NavLink>
                    </li>


                </ul>
            </div>

            <div className={cn(css(styles.container))}>
                <div className={cn(className, css(styles.content), { [css(styles.padding)]: padding })}>
                    <div>
                        <i onClick={() => setMenu(true)} className={cn(
                            'icon ion-md-menu is-size-3',
                            css(styles.showerIcon),
                        )} />
                    </div>
                    {children}
                </div>
            </div>

            <div className={css(menu ? styles.background : null)} onClick={() => setMenu(false)} />
        </div>
    )
}

const styles = StyleSheet.create({
    sidebar: {
        minHeight: '100%',
        position: 'fixed',
        background: '#1f2668',
        width: '14.1rem',
        margin: '0',
        color: 'white',
        padding: '0 1.2rem 0 2.8rem',
        zIndex: '5',
        '::-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-height: 525px)': {
            height: 'calc(100vh - 100%)',
            overflowX: 'scroll',
        },
    },
    developmentSidebar: {
        // background: '#004d40',
    },
    stagingSidebar: {
        background: '#000000',
    },
    desktopSidebar: {
        '@media (max-width: 769px)': {
            transform: 'translateX(0)',
            transition: 'all 300ms',
        },
    },
    mobileSidebar: {
        '@media (max-width: 769px)': {
            transform: 'translateX(-14rem)',
            transition: 'all 300ms',
        },
    },
    icon: {
        margin: '0 0.3rem 0.9rem 0',
    },
    link: {
        color: 'RGBA(255, 255, 255, .6)',
    },
    logo: {
        margin: '3.4rem 0.4rem',
        width: '8rem',
    },
    menuHeading: {
        color: 'RGBA(255,255,255, .6)',
        margin: '0 0 1.5rem 0',
    },
    menu: {
        margin: '0 0 3.1rem 0',
    },
    padding: {
        padding: '1.8rem',
    },
    container: {
        margin: '0 0 0 14rem',
        '@media (max-width: 769px)': {
            margin: '0',
        },
    },
    active: {
        fontWeight: 'bolder',
        color: 'white',
    },
    content: {
        minHeight: '100vh',
    },
    showerIcon: {
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    hiderIcon: {
        transform: 'translate(9.6rem, -15rem)',
        color: 'white',
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    background: {
        '@media (max-width: 769px)': {
            bottom: 0,
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            background: 'RGBA(0, 0, 0, .5)',
            zIndex: 4,
        },
    },
})
