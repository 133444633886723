import isEmpty from 'lodash/isEmpty'
import React, { useMemo } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { keyBy } from 'lodash'
import CustomerBoard from '../../components/center/CustomerBoard'
import AcademyTabs from '../../components/center/AcademyTabs'
import CustomersFilter from '../../components/center/CustomersFilter'
import CustomersActionsBar from '../../components/center/CustomersActionsBar'
import Layout from '../../components/center/Layout'
import { useQueryParams } from '../../hooks/queryString'
import { usePersistState } from '../../hooks/state'
import CustomerTable from '../../components/center/CustomerTable'
import { useInfiniteScroll, useLoad } from '../../hooks/request'
import { CUSTOMER_LIST, STATUS_LIST } from '../../urls'
import { PermissionDenied } from '../../components/center/PermissionDenied'
import { checkPermission as cp } from '../../utils/auth'


export default function Customers() {
    const [view, setView] = usePersistState('customersView', 'board') // or table
    const queryParams = useQueryParams()
    const params = { type: 'active', ...queryParams }
    const customers = useInfiniteScroll({ url: CUSTOMER_LIST, params })
    const status = useLoad({ url: STATUS_LIST })
    const statusList = status.response && status.response.results ? status.response.results : []

    // convert `[{ id: 1 }]` to {1: { id: 1 }}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const statsObj = useMemo(() => keyBy(statusList, 'id'), [statusList.length])

    async function onUpdate(customerId, data) {
        const results = isEmpty(data) ? (
            await customers.response.results.filter((item) => item.id !== customerId)
        ) : (
            await customers.response.results.map((item) => (
                item.id === customerId ? { ...item, ...data } : item
            ))
        )
        customers.setResponse({ count: customers.response.count, results })
    }

    if (!cp('academy.view_customer')) {
        return <PermissionDenied />
    }

    return (
        <Layout className={view !== 'table' ? 'has-background-light' : null}>
            <AcademyTabs />

            <div className={cn('columns', css(styles.searchBar))}>
                <div className="column is-4 is-offset-8">
                    <CustomersFilter className={css(styles.search)} />
                </div>
            </div>

            <CustomersActionsBar view={view} onViewChange={setView} />

            {view === 'table' ? (
                <CustomerTable
                    type={params.type}
                    statsObj={statsObj}
                    onUpdate={onUpdate}
                    statuses={statusList}
                    customers={customers} />
            ) : (
                <CustomerBoard
                    type={params.type}
                    onUpdate={onUpdate}
                    columns={statusList}
                    customers={customers}
                    onReloadStatues={status.request} />
            )}
        </Layout>
    )
}


const styles = StyleSheet.create({
    column: {
        marginRight: '10px',
    },
    searchBar: {
        '@media (min-width: 900px)': {
            height: '0',
        },
    },
    search: {
        '@media (min-width: 900px)': {
            marginTop: '-4rem',
        },
    },
})
