/* eslint-disable no-nested-ternary */
import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { SCHOOL_STUDENT_DETAIL } from '../../urls'
import { useDeleteRequest } from '../../hooks/request'
import { useModal } from '../../hooks/modal'
import { useMessage } from '../../hooks/message'
import StudentUpdate from './StudentUpdate'
import Button from '../common/Button'

export default function StudentItem({ item, onDelete, onUpdate, index }) {
    const employeeDelete = useDeleteRequest({ url: SCHOOL_STUDENT_DETAIL.replace('{studentId}', item.id) })

    const [showUpdateModal, hideUpdateModal] = useModal(
        <StudentUpdate employee={item} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    const [showMessage] = useMessage()

    async function deleteGroup() {
        if (global.confirm('Haqiqatan ham oʻchirib tashlamoqchimisiz?')) {
            const { error } = await employeeDelete.request()
            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }
            onDelete()
        }
    }

    return (
        <tr>
            <td>
                {index + 1}
            </td>
            <td>
                <NavLink to={`/student/${item.id}`} className="has-text-dark">
                    <span className="has-text-link">{item.name}</span>
                </NavLink>
            </td>

            <td>
                {item.birthday
                    ? new Date(item.birthday).toLocaleDateString()
                    : <span className="is-italic has-text-grey">ko'rsatilmagan</span>}
            </td>

            <td>
                {item.phoneNumber}
            </td>
            <td>
                {item.phoneNumberSecond ? item.phoneNumberSecond : null}
            </td>

            <td>

                {item.connectionStatus === 'Ulangan'
                    ? <span className="is-italic is-success tag is-light">{item.connectionStatus}</span> : null}

                {item.connectionStatus === 'Ulanmagan'
                    ? <span className="is-italic is-danger tag is-light">{item.connectionStatus}</span> : null}


            </td>
            <td>
                {item.group.map((elem) => (
                    <span style={{ marginRight: 3 }} key={item} className={cn('tag is-small is-light is-link')}>
                        {elem.name}
                    </span>
                ))}
            </td>

            <td className="has-text-right">
                <div className="is-flex">
                    <Button
                        onClick={deleteGroup}
                        icon="ion-md-trash"
                        className="is-danger is-outlined mr-2" />
                    <Button
                        onClick={showUpdateModal}
                        icon="ion-md-create"
                        className="is-success is-outlined mb-2" />
                </div>
            </td>
        </tr>
    )
}
