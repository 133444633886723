import React from 'react'
import map from 'lodash/map'
import { usePostRequest } from '../../hooks/request'
import StudentForm from './StudentForm'
import ServerError from '../common/ServerError'
import { SCHOOL_STUDENT_LIST } from '../../urls'
import { useMessage } from '../../hooks/message'

export default function StudentCreate({ onCancel, onSuccess }) {
    const studentCreate = usePostRequest({ url: SCHOOL_STUDENT_LIST })
    const [showMessage] = useMessage()
    async function onSubmit(data, actions) {
        const { success } = await studentCreate.request({ data: {
            ...data, group: map(data.group, 'value'),
        } })

        if (success) {
            actions.resetForm()
            onSuccess()
            showMessage('Yangi o\'quvchi muvofaqiyatli qo\'shildi.', 'is-success')
        }
    }

    return (
        <div>
            <b>Yangi o'quvchi qo'shing</b><br /><br />
            <ServerError error={studentCreate.error} />
            <StudentForm onSubmit={onSubmit} onCancel={onCancel} loading={studentCreate.loading} />
        </div>
    )
}
