import React from 'react'
import ReactApexChart from 'react-apexcharts'

export default function Bar({ absentCounts, groups, loading }) {
    const series = [{
        // eslint-disable-next-line radix
        name: "Darsga kelgan <br/> o'quvchilar   soni", data: absentCounts.map((item) => parseInt(item, 10)),
    }]
    const colors = ['#38CB89']
    const options = {
        chart: {
            // events: {
            //     click(chart, w, e) {
            //     },
            // },
            width: 'auto',
        },
        colors,
        plotOptions: {
            bar: {
                columnWidth: '45%', distributed: true, borderRadius: 10, borderRadiusApplication: 'end',
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        xaxis: {
            categories: groups.map((item) => item),
            labels: {
                style: {
                    colors: ['#7D8488'], fontSize: '13px', fontFamily: 'Gilroy',
                },
            },
        },
        yaxis: {
            labels: {
                formatter(val) {
                    return val.toFixed(0)
                },
                style: {
                    colors: ['#7D8488'], fontSize: '14px', fontFamily: 'Gilroy',
                },
            },
        },
    }
    return (
        <ReactApexChart
            loading={loading}
            options={options}
            series={series}
            type="bar"
            height={450}
        />
    )
}
