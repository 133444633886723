/* eslint-disable no-nested-ternary */
import React from 'react'
import { useDeleteRequest, usePutRequest } from '../../hooks/request'
import { TASK_DETAIL } from '../../urls'
import { checkPermission as cp } from '../../utils/auth'
import { useModal } from '../../hooks/modal'
import { getDateTime } from '../../utils/date'
import Loader from '../common/Loader'
import TaskUpdate from './TaskUpdate'

export default function TaskItem({ item, onDelete, onUpdate }) {
    const deleteTask = useDeleteRequest({ url: TASK_DETAIL.replace('{id}', item.id) })
    const changeStatusTask = usePutRequest({ url: TASK_DETAIL.replace('{id}', item.id) })

    async function handleDeleteTask() {
        if (global.confirm('Haqiqatan ham oʻchirib tashlamoqchimisiz?')) {
            await deleteTask.request()
            onDelete()
        }
    }

    async function changeStatus() {
        if (global.confirm('Haqiqatan ham holatni o‘zgartirmoqchimisiz?')) {
            await changeStatusTask.request({
                data: {
                    ...item,
                    assigned: item.assigned.id,
                    status: item.status === 'waiting' ? 'complete' : 'waiting',
                },
            })
            onUpdate()
        }
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <TaskUpdate task={item} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    return (
        <tr>
            <td>{item.title || <span className="is-italic has-text-grey">ko'rsatilmagan</span>}</td>
            <td>{item.assigned.firstName || item.assigned.lastName || item.assigned.email}</td>

            <td>
                {!item.dueTime ? (
                    <span className="is-italic has-text-grey">ko'rsatilmagan</span>
                ) : getDateTime(item.dueTime)}
            </td>

            <td onClick={changeStatus}>
                {item.status === 'complete' ? (
                    <span className="tag is-success is-rounded pointer">Bajarildi</span>
                ) : <span className="tag is-warning pointer is-rounded">Tugallanishi kutilmoqda</span>}
            </td>

            <td>
                {cp('task.delete_task') ? ((
                    !deleteTask.loading ? (
                        <i onClick={handleDeleteTask} className="icon ion-md-trash pointer" />
                    ) : <Loader className="icon" />
                )) : null}

                {cp('task.change_task') ? (
                    <i onClick={showUpdateModal} className="icon ion-md-create pointer" />
                ) : null}
            </td>
        </tr>
    )
}
