import { Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { required } from '../../utils/validators'
import Input from '../common/Input'
import Button from '../common/Button'
import Select from '../common/Select'
import { useGetRequest, useLoad } from '../../hooks/request'
import { SCHOOL_GROUP_LIST, SCHOOL_STUDENT_DETAIL, SCHOOL_STUDENT_LIST } from '../../urls'
import { groups, months, statuses } from '../../utils/position'
import CustomSelect from '../common/CustomSelect'
import MultiSelect from '../common/MultiSelect'
import { format } from "../../utils/number";

export default function InvoiceForm({ onSubmit, onCancel, loading, initialValues }) {
    console.log(initialValues)
    const [studentId, setStudentId] = useState(initialValues && !isEmpty(initialValues.id) ? initialValues?.id : null)
    const subject = useLoad({ url: SCHOOL_GROUP_LIST, params: { size: 20, student: studentId?.id } }, [studentId?.id])
    const groupItem = subject.response ? subject.response.results : []
    const options = Object.entries(groupItem).map(([, {
        id, name,
    }]) => ({
        id, name: id, label: name, value: id,
    }))
    const month = Object.entries(months).map(([id, name]) => ({ id, name }))
    const status = Object.entries(statuses).map(([id, name]) => ({ id, name }))
    const group = Object.entries(groups).map(([id, name]) => ({ id, name }))
    const student = useLoad({ url: SCHOOL_STUDENT_LIST, params: { size: 1000 } })
    const studentItem = student.response ? student.response.results : []
    const studentOptions = Object.entries(studentItem).map(([, {
        id,
        name,
    }]) => ({
        id,
        name: id,
        label: name,
    }))

    const studentDetailUrl = SCHOOL_STUDENT_DETAIL.replace('{studentId}', studentId?.id)
    const studentDetail = useGetRequest({ url: studentDetailUrl })
    const studentDetailItem = studentDetail.response ? studentDetail.response : {}

    useEffect(() => {
        if (studentId?.id || initialValues?.student?.id) {
            studentDetail.request();
        }
    }, [studentId, initialValues?.student?.id]);

    useEffect(() => {
        function updateGroupValues(setFieldValue) {
            if (!isEmpty(studentDetailItem.group)) {
                const groupValues = studentDetailItem.group.map(item => ({
                    id: item.id,
                    name: item.id,
                    label: item.name,
                    value: item.id,
                }));
                setFieldValue('group', groupValues);
            } else {
                setFieldValue('group', '');
            }
        }

        if (formikRef.current) {
            updateGroupValues(formikRef.current.setFieldValue);
        }
    }, [studentDetailItem]);

    const formikRef = useRef();

    return (
        <Formik initialValues={{
            fee: '',
            borrow: 0,
            forMonth: '',
            status: 'canceled',
            group: [],
            ...initialValues,
            student: initialValues && !isEmpty(initialValues.student) ? {
                label: initialValues.student.name,
                name: initialValues.student.id,
                id: initialValues.student.id,
            } : '',

        }} onSubmit={onSubmit}>
            {({ setFieldValue, }) => {
                formikRef.current = { setFieldValue }
                return (
                    <Form>
                        <CustomSelect
                            label="O'quvchining ismi va familiyasi"
                            placeholder="O'quvchining ismi va familiyasi tanlang..."
                            name="student"
                            onSelectItem={setStudentId}
                            options={studentOptions}/>

                        {!initialValues ? <MultiSelect
                            label="Sinf yoki kurslarini tanlang"
                            name="group"
                            placeholder="O'quvchi sinf va kurslarini tanlang"
                            options={options}/> : null}

                        {studentDetailItem ? <div className="mb-2">
                            {studentDetailItem.isBus ?
                                <span className="tag is-warning is-medium mr-2">Avtobus</span> : null}
                            {studentDetailItem.isFreedom ?
                                <span className="tag is-link is-medium mr-2">Ovqat</span> : null}
                            {studentDetailItem.isLunch ?
                                <span className="tag is-primary is-medium mr-2">Imtiyoz</span> : null}

                        </div> : null}
                        {studentDetailItem.price ? <div className="mb-2">
                    <span
                        className="tag is-danger is-medium mr-2">Jami: {format(studentDetailItem.price) || 0} so'm</span>

                        </div> : null}
                        <Input
                            type="number"
                            label="To'lov summasi"
                            name="fee"
                            disabled={loading}
                            placeholder="To'lov summasini kiriting"/>
                        <Input
                            type="number"
                            label="Qarz summasi"
                            name="borrow"
                            disabled={loading}
                            placeholder="Qarz summasini kiriting"/>


                        <Select
                            label="Oyni tanlang"
                            loading={subject.loading}
                            name="forMonth"
                            options={month}
                            validate={required}/>

                       {/* <Select
                            className="is-hidden"
                            label="Statusni tanlang"
                            loading={subject.loading}
                            name="status"
                            options={status}
                            validate={required}/>*/}

                        <Button
                            loading={loading}
                            text="Saqlash"
                            type="submit"
                            icon="ion-md-checkmark"
                            className="is-success"/> &nbsp;

                        <Button
                            onClick={onCancel}
                            icon="ion-md-close"
                            text="Bekor qilish"
                            className="is-danger"/>
                    </Form>
                )
            }}
        </Formik>
    )
}
