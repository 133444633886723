import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import sendMessage from '../static/sendMessage.png'

export default function ResetPasswordMessage() {
    return (
        <div className={css(styles.container)}>
            <img className={css(styles.img)} src={sendMessage} alt="" />
            <p className={css(styles.text)}>
                <span className="is-size-3">Xabar yuborildi!</span><br />
                Parolingizni o'zgartirish uchun elektron pochta manzilingizga havola yubordik, iltimos, unga rioya
                qiling va parolingizni o'zgartiring.

            </p>
            <p className="has-text-centered"><a href="/" className="is-link button fa-align-center">Orqaga</a></p>
        </div>
    )
}

const styles = StyleSheet.create({
    container: {
        margin: '0 auto',
        width: '50rem',
    },
    img: {
        width: '100%',
        margin: '0 auto',
    },
    text: {
        margin: '0 auto',
        padding: '3rem 7rem',
    },
})
