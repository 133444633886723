import React from 'react'
import Layout from '../../components/center/Layout'
import { useLoad } from '../../hooks/request'
import { SCHOOL_GROUP_LIST } from '../../urls'

import Loader from '../../components/common/Loader'
import CourseGroupItem from '../../components/school/CourseGroupItem'
import Button from '../../components/common/Button'
import { useModal } from '../../hooks/modal'
import GroupCreate from '../../components/school/GroupCreate'

export default function Group() {
    const groups = useLoad({ url: SCHOOL_GROUP_LIST, params: { type: 'course', size: 100 } })


    const [showCreateModal, hideCreateModal] = useModal(
        <GroupCreate
            onSuccess={() => {
                groups.request()
                hideCreateModal()
            }} onCancel={() => hideCreateModal()} />,
    )
    return (
        <Layout className="has-background-light">
            <span className="is-size-4">Kurslar</span>
            <div className="columns is-pulled-right">
                <Button
                    onClick={showCreateModal}
                    text="Qo'shish"
                    icon="ion-md-add"
                    className="is-link is-outlined" />
            </div>

            <br />
            <br />

            <div className="columns is-multiline">
                {!groups.loading && groups.response ? groups.response.results.map((wallet) => (
                    <CourseGroupItem onDelete={groups.request} groups={groups} key={wallet.id} wallet={wallet} />
                )) : null}
                <Loader show={groups.loading} large center />

            </div>
            <div ref={groups.ref} className="has-text-grey-light is-italic has-text-centered">
                {!groups.hasMore && !groups.loading && groups.length !== 0 ? 'Barcha guruhlar yuklandi' : ''}
            </div>
        </Layout>
    )
}
