import React from 'react'
import Layout from '../../components/center/Layout'
import WalletItem from '../../components/center/WalletItem'
import { useLoad } from '../../hooks/request'
import { WALLET_LIST } from '../../urls'
import Loader from '../../components/common/Loader'
import { checkPermission as cp } from '../../utils/auth'
import { PermissionDenied } from '../../components/center/PermissionDenied'
import FinanceTabs from '../../components/center/FinanceTabs'

export default function Wallet() {
    const wallets = useLoad({ url: WALLET_LIST })

    if (!cp('finance.view_wallet')) {
        return <PermissionDenied />
    }

    return (
        <Layout className="has-background-light">
            <FinanceTabs />
            {/* <span className="is-size-4">Счета</span><br /><br /> */}

            <div className="columns is-multiline">
                { !wallets.loading && wallets.response ? wallets.response.results.map((wallet) => (
                    <WalletItem key={wallet.id} wallet={wallet} />
                )) : null}

                <Loader show={wallets.loading} large center />
            </div>
        </Layout>
    )
}
