import React from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import Layout from '../../components/center/Layout'
import StatisticsFinanceOutcome from '../../components/center/StatisticsFinanceOutcome'
import DateRange from '../../components/common/DateRange'
import { useQueryParams } from '../../hooks/queryString'
import { DATE_FORMAT } from '../../utils/date'
import StatisticsTabs from '../../components/center/StatisticsTabs'
import StatisticsFinanceAccountant from '../../components/center/StatisticsFinanceAccountant'
import Card from '../../components/common/Card'


export default function StatisticsFinance() {
    const history = useHistory()
    const params = useQueryParams()
    const startDate = params.startDate || moment().subtract(1, 'months').format(DATE_FORMAT)
    const endDate = params.endDate || moment().format(DATE_FORMAT)

    return (
        <Layout className="has-background-light">
            <StatisticsTabs />
            <Card>
                <DateRange
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={(dates) => {
                        history.push(`?${queryString.stringify({ ...params, ...dates })}`)
                    }} />
            </Card><br />

            <StatisticsFinanceAccountant /><br />

            <StatisticsFinanceOutcome
                startDate={startDate}
                endDate={endDate} />
        </Layout>
    )
}
