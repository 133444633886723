/* eslint-disable no-nested-ternary */
import React from 'react'
import { SUBJECT_DATE_TIME_DETAIL } from '../../urls'
import { useDeleteRequest } from '../../hooks/request'
import { useModal } from '../../hooks/modal'
import { checkPermission as cp } from '../../utils/auth'
import { useMessage } from '../../hooks/message'
import Loader from '../common/Loader'
import HourUpdate from './HourUpdate'

export default function HourItem({ item, onDelete, onUpdate }) {
    const employeeDelete = useDeleteRequest({ url: SUBJECT_DATE_TIME_DETAIL.replace('{id}', item.id) })

    const [showUpdateModal, hideUpdateModal] = useModal(
        <HourUpdate employee={item} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    const [showMessage] = useMessage()

    async function deleteGroup() {
        if (global.confirm('Haqiqatan ham oʻchirib tashlamoqchimisiz?')) {
            const { error } = await employeeDelete.request()
            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }
            onDelete()
        }
    }

    return (
        <tr>
            <td>
                {item.name}
            </td>

            <td className="has-text-right">
                {cp('staff.delete_employee') ? (
                    !employeeDelete.loading ? (
                        <i onClick={() => deleteGroup()} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />
                ) : null}

                {cp('staff.change_employee') ? (
                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                ) : null}
            </td>
        </tr>
    )
}
