import React, { useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import Layout from '../../components/center/Layout'
import { useLoad } from '../../hooks/request'
import { INVOICE_LIST } from '../../urls'
import { useQueryParams } from '../../hooks/queryString'
import { useModal } from '../../hooks/modal'
import Table from '../../components/common/Table'
import InvoiceCreate from '../../components/school/InvoiceCreate'
import InvoiceItem from '../../components/school/InvoiceItem'
import FinanceTabs from '../../components/center/FinanceTabs'
import CustomersFilter from '../../components/center/CustomersFilter'
import Button from '../../components/common/Button'
import InvoiceItemCanceled from '../../components/school/InvoiceItemCanceled'


export default function InvoiceCanceled() {
    const params = useQueryParams()
    const [page, setPage] = useState(1)
    const employees = useLoad({
        url: INVOICE_LIST,
        params: { ...params, page, status: 'canceled' },
    }, [page, params])

    const [showCreateModal, hideCreateModal] = useModal(
        <InvoiceCreate onSuccess={() => {
            hideCreateModal()
            employees.request()
        }} onCancel={() => hideCreateModal()} />,
    )


    return (
        <Layout>
            <FinanceTabs />

            <div className={cn('columns', css(styles.searchBar))}>
                <div className="column is-4 is-offset-8">
                    <CustomersFilter className={css(styles.search)} />
                </div>
            </div>

            <div className={cn('columns', css(styles.searchBar))}>
                <div className="column is-4 is-offset-8">
                    <div className={css(styles.button)}>
                        <Button
                            onClick={showCreateModal}
                            text="Qo'shish"
                            icon="ion-md-add"
                            className="is-link is-outlined is-pulled-right mb-2" />
                    </div>

                </div>
            </div>


            <br className={css(styles.br)} />

            <div className={css(styles.table)}>
                <div className={css(styles.tableItem)}>
                    <Table
                        activePage={page}
                        onPageChange={setPage}
                        loading={employees.loading}
                        totalCount={employees.response ? employees.response.count : 0}
                        items={employees.response ? employees.response.results : []}
                        columns={{
                            name: 'Ism',
                            birthday: 'Maktab yoki kurs',
                            salary: "To'lov summasi",
                            contacts: 'Oy',
                            position: 'Status',
                            actions: '',
                        }}
                        renderItem={(item) => (
                            <InvoiceItemCanceled key={item.id} onUpdate={employees.request} onDelete={employees.request}
                                item={item} />
                        )} />
                </div>
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    column: {
        marginRight: '10px',
    },
    searchBar: {
        '@media (min-width: 900px)': {
            height: '0',
        },
    },
    search: {
        '@media (min-width: 900px)': {
            marginTop: '-4rem',
        },
    },
    button: {
        '@media (min-width: 900px)': {
            marginTop: '-1rem',
        },
    },

    table: {
        '@media (max-width: 500px)': {
            overflowX: 'scroll',
        },
    },
    tableItem: {
        '@media (max-width: 500px)': {
            width: 800,
        },
    },
    br: {
        '@media (max-width: 500px)': {
            width: 800,
        },
    },
})
