import { Form, Formik } from 'formik'
import React from 'react'
import find from 'lodash/find'
import { max, min, required, validator } from '../../utils/validators'
import { positions } from '../../utils/position'
import Input from '../common/Input'
import Button from '../common/Button'
import MultiSelect from '../common/MultiSelect'

export default function EmployeeForm({ onSubmit, onCancel, loading, initialValues }) {
    const options = Object.entries(positions).map(([value, label]) => ({ value, label }))

    return (
        <Formik initialValues={{
            name: '',
            birthday: '',
            phoneNumber: '',
            salary: 0,
            academyShare: 0,
            sellerShare: 0,
            positions: [],
            ...initialValues,
        }} onSubmit={onSubmit}>
            {({ values }) => (
                <Form>
                    <MultiSelect
                        validate={required}
                        label="Lavozim"
                        name="positions"
                        placeholder="Xodimlarning lavozimlarini kiriting"
                        options={options} />

                    <Input
                        label="Ism"
                        name="name"
                        disabled={loading}
                        validate={required}
                        placeholder="Xodim nomini kiriting" />

                    <Input
                        name="birthday"
                        type="date"
                        label="Tug'ilgan kun"
                        disabled={loading}
                        validate={required}
                        placeholder="Xodimning tug'ilgan kunini kiriting" />

                    {find(values.positions, { value: 'teacher' }) ? (
                        <Input
                            name="academyShare"
                            type="number"
                            label="O'qituvchi ulushi"
                            disabled={loading}
                            validate={validator(required, max(100), min(0))}
                            placeholder="O'qituvchining ulushini kiriting" />
                    ) : null}

                    <Input
                        name="phoneNumber"
                        label="Telefon raqami"
                        type="number"
                        disabled={loading}
                        placeholder="O'qituvchining telefon raqamini kiriting" />
                    <Input
                        name="sellerShare"
                        label="Sotish ulushi"
                        type="number"
                        disabled={loading}
                        validate={validator(required, max(100), min(0))}
                        placeholder="Sotuvchining ulushini kiriting" />

                    <Input
                        name="salary"
                        label="Belgilangan ish haqi"
                        type="number"
                        disabled={loading}
                        validate={validator(required, min(0))}
                        placeholder="Xodimning ish haqini kiriting" />

                    <Button
                        loading={loading}
                        text="Saqlash"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;


                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Bekor qilish"
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
