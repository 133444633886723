import React from 'react'
import { usePostRequest } from '../../hooks/request'
import ServerError from '../common/ServerError'
import { SUBJECT_LIST } from '../../urls'
import SubjectForm from './SubjectForm'
import { useMessage } from '../../hooks/message'

export default function SubjectCreate({ onCancel, onSuccess }) {
    const studentCreate = usePostRequest({ url: SUBJECT_LIST })
    async function onSubmit(data, actions) {
        const { success } = await studentCreate.request({
            data,
        })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <b>Добавить нового ученика</b><br /><br />
            <ServerError error={studentCreate.error} />
            <SubjectForm onSubmit={onSubmit} onCancel={onCancel} loading={studentCreate.loading} />
        </div>
    )
}
