import React from 'react'
import Layout from '../../components/center/Layout'
import { useLoad } from '../../hooks/request'
import { SCHOOL_GROUP_LIST } from '../../urls'
import Loader from '../../components/common/Loader'
import TimeTableItem from '../../components/school/TimeTableItem'
import TimeTableTabs from '../../components/school/TimeTableTabs'

export default function TimeTable() {
    const groups = useLoad({ url: SCHOOL_GROUP_LIST, params: { type: 'school', size: 20 } })

    return (
        <Layout className="has-background-light">
            <TimeTableTabs />
            <div className="columns is-multiline">
                {!groups.loading && groups.response ? groups.response.results.map((wallet) => (
                    <TimeTableItem onDelete={groups.request} groups={groups} key={wallet.id} wallet={wallet} />
                )) : null}
                <Loader show={groups.loading} large center />

            </div>
            <div ref={groups.ref} className="has-text-grey-light is-italic has-text-centered">
                {!groups.hasMore && !groups.loading && groups.length !== 0 ? 'Barcha guruhlar yuklandi' : ''}
            </div>
        </Layout>
    )
}
