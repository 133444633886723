import React from 'react'
import map from 'lodash/map'
import { SCHOOL_STUDENT_DETAIL } from '../../urls'
import { usePutRequest } from '../../hooks/request'
import StudentForm from './StudentForm'
import ServerError from '../common/ServerError'
import { useMessage } from '../../hooks/message'

export default function StudentUpdate({ onCancel, onUpdate, employee }) {
    const url = SCHOOL_STUDENT_DETAIL.replace('{studentId}', employee.id)
    const employeeUpdate = usePutRequest({ url })
    const [showMessage] = useMessage()
    async function onSubmit(data, actions) {
        const { success } = await employeeUpdate.request({
            data: {
                ...data, group: map(data.group, 'value'),
            },
        })

        if (success) {
            showMessage('O\'quvchining ma\'lumotlari muvofaqiyatli o\'zgartirildi.', 'is-success')
            actions.resetForm()
            onUpdate()
        }
    }

    const initialValues = {
        ...employee,
        group: Object.entries(employee.group).map(([, {
            id, name,
        }]) => ({
            id, name: id, label: name, value: id,
        })),
    }

    return (
        <div>
            <b>O'quvchining ma'lumotlarini tahrirlash</b><br /><br />
            <ServerError error={employeeUpdate.error} />
            <StudentForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={employeeUpdate.loading} />
        </div>
    )
}
