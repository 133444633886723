/* eslint-disable no-nested-ternary */
import React from 'react'
import cn from 'classnames'
import { INVOICE_DETAIL } from '../../urls'
import { useDeleteRequest } from '../../hooks/request'
import { useModal } from '../../hooks/modal'
import { checkPermission as cp } from '../../utils/auth'
import { useMessage } from '../../hooks/message'
import Loader from '../common/Loader'
import InvoiceUpdate from './InvoiceUpdate'
import { format } from '../../utils/number'
import { months } from '../../utils/position'
import Button from '../common/Button'

export default function InvoiceItemCanceled({ item, onDelete, onUpdate }) {
    const employeeDelete = useDeleteRequest({ url: INVOICE_DETAIL.replace('{id}', item.id) })

    const [showUpdateModal, hideUpdateModal] = useModal(
        <InvoiceUpdate employee={item} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    const [showMessage] = useMessage()

    async function deleteGroup() {
        if (global.confirm('Haqiqatan ham oʻchirib tashlamoqchimisiz?')) {
            const { error } = await employeeDelete.request()
            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }
            onDelete()
        }
    }

    return (
        <tr>
            <td>
                {item.student.name}
            </td>
            <td>
                {item.group.map((elem) => (
                    <span style={{ marginRight: 3 }} key={item} className={cn('tag is-small is-light is-link')}>
                        {elem.name}
                    </span>
                ))}
            </td>
            <td className="has-text-link">
                {format(item.fee)} so'm
            </td>
            <td>
                <span className="tag is-small is-warning is-light">
                    {months[item.forMonth.toLowerCase()]}
                </span>
            </td>
            {item.status === 'canceled' ? (
                <td>
                    <span className="tag is-success is-small is-light button">
                        To'langan
                    </span>
                </td>
            ) : null}
            {item.status === 'complete' ? (
                <td>
                    <span className="tag is-danger is-medium is-light button">
                        To'lanmagan
                    </span>
                </td>
            ) : null}


            <td className="has-text-right">
                <div className="is-flex">
                    {cp('staff.delete_employee') ? (
                        !employeeDelete.loading ? (
                            <Button
                                onClick={deleteGroup}
                                icon="ion-md-trash"
                                className="is-danger is-outlined mr-2" />
                        ) : <Loader className="icon" />
                    ) : null}
                    {cp('staff.change_employee') ? (
                        <Button
                            onClick={showUpdateModal}
                            icon="ion-md-create"
                            className="is-success is-outlined mb-2" />
                    ) : null}
                </div>
            </td>


        </tr>
    )
}
