import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { StyleSheet } from 'aphrodite'
import Layout from '../../components/center/Layout'
import { SCHOOL_GROUP_DETAIL, TIMETABLE_LIST } from '../../urls'
import { useLoad } from '../../hooks/request'
import Button from '../../components/common/Button'
import Loader from '../../components/common/Loader'
import WeekDay from '../../components/school/WeekDay'

export default function TimeTableDetail() {
    const { classId } = useParams()
    const classDetail = useLoad({ url: SCHOOL_GROUP_DETAIL.replace('{id}', classId) })
    const assessments = useLoad({ url: TIMETABLE_LIST, params: { group: classId } })
    console.log(assessments.response ? assessments.response : [])
    const className = classDetail.response
    const history = useHistory()


    return (
        <Layout>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <div className="mb-2" style={{ flexBasis: 'auto' }}>
                    <Button
                        onClick={() => history.push('/timetable/school')}
                        icon="ion-md-arrow-back"
                        className="is-link is-outlined mr-2" />
                </div>
                <div className="mb-2" style={{ flexBasis: 'auto' }}>
                    <span className="mr-2 tag is-success is-large">Sinf : {className ? className.name : ''}</span>
                </div>
            </div>

            <br />
            <div className="columns is-multiline">
                {!assessments.loading && assessments.response ? assessments.response.results.map((assessment, index) => (
                    <WeekDay
                        index={index}
                        onDelete={assessments.request}
                        onUpdate={assessments.request}
                        assessment={assessment}
                        classId={classId} />
                )) : null}
                <Loader show={assessments.loading} large center />
            </div>

            <div ref={assessments.ref} className="has-text-grey-light is-italic has-text-centered">
                {!assessments.hasMore && !assessments.loading && assessments.length !== 0 ? 'Barcha kunlar yuklandi' : ''}
            </div>

        </Layout>
    )
}

const styles = StyleSheet.create({

    assessment: {
        '@media (max-width: 500px)': {
            overflowX: 'scroll',
        },
    },
    assessmentTable: {
        '@media (max-width: 500px)': {
            width: '800px',
        },
    },
})
