import { Form, Formik } from 'formik'
import React from 'react'
import moment from 'moment'
import { usePostRequest } from '../../hooks/request'
import { TASK_LIST } from '../../urls'
import { required } from '../../utils/validators'
import Button from '../common/Button'
import Input from '../common/Input'
import { Radio, RadioGroup } from '../common/Radio'

export default function CustomerMeeting({ onSuccess, customer }) {
    // Tomorrow as 12:00
    const dueTime = moment({ hour: 12 }).add(1, 'day').format(moment.HTML5_FMT.DATETIME_LOCAL)
    const initialValues = { title: '', action: 'call', dueTime }
    const taskCreate = usePostRequest({ url: TASK_LIST })

    async function onSubmit(data) {
        const user = JSON.parse(localStorage.getItem('user'))

        const title = data.action === 'call'
            ? `Позвонить клиенту "${customer.name}" (${customer.phone})`
            : `Встретится с клиентом "${customer.name}" (${customer.phone})`

        await taskCreate.request({ data: { ...data, customer: customer.id, assigned: user.id, title } })
        onSuccess()
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
            <Form>
                <div className="columns">
                    <div className="column is-narrow">
                        <b>Назначить встречу со студентом {customer.name}</b>
                    </div>
                </div>

                <Input
                    name="dueTime"
                    type="datetime-local"
                    placeholder="Дата"
                    validate={required} />

                <RadioGroup name="action">
                    <Radio label="Позвонить" value="call" />
                    <Radio label="Встретиться" value="meet" />
                </RadioGroup>

                <Button
                    loading={taskCreate.loading}
                    text="Qo'shish"
                    type="submit"
                    icon="ion-md-add"
                    className="is-info" />
            </Form>
        </Formik>
    )
}
