import { Form, Formik } from 'formik'
import React from 'react'
import { minTime, required, validator } from '../../utils/validators'
import Input from '../common/Input'
import Button from '../common/Button'

export default function HourForm({ onSubmit, onCancel, loading, initialValues }) {
    return (
        <Formik initialValues={{
            name: '',
            dateStart: '08:00',
            dateEnd: '20:59',

            ...initialValues,
        }} onSubmit={onSubmit}>
            {({ values: { startTime } }) => (
                <Form>
                    <Input
                        label="Имя"
                        name="name"
                        disabled={loading}
                        validate={required}
                        placeholder="Введите имя студента" />

                    <div className="columns">
                        <div className="column is-narrow">
                            <Input label="C" validate={required} name="dateStart" type="time" step="600" />
                        </div>

                        <div className="column is-narrow">
                            <Input
                                label="По"
                                validate={validator(required, minTime(startTime))}
                                name="dateEnd"
                                type="time"
                                step="600" />
                        </div>
                    </div>


                    <Button
                        loading={loading}
                        text="Saqlash"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;


                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Bekor qilish"
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
