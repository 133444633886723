import React from 'react'
import { format } from '../../utils/number'
import { getMonth } from '../../utils/date'
import { useModal } from '../../hooks/modal'
import EmployeeHistorySalary from './EmployeeHistorySalary'

export default function EmployeeSalaryItem({ item, employee }) {
    const [showSalaryModal, hideSalaryModal] = useModal(
        <EmployeeHistorySalary
            employeeSalary={item}
            employeeDetail={employee}
            onCancel={() => hideSalaryModal()} />,
    )

    return (
        <tr>
            <td>
                {getMonth(item.month, true) || <span className="is-italic has-text-grey">ko'rsatilmagan</span>}
            </td>

            <td>
                <span className="has-text-success">{format(item.profit || 0)} сум </span> /&nbsp;
                <span className="has-text-link">{format(item.payed)} сум </span>
            </td>

            <td>
                <span onClick={showSalaryModal}><i className="icon ion-md-eye" /></span>
            </td>
        </tr>
    )
}
