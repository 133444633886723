import React from 'react'
import { SUBJECT_DETAIL } from '../../urls'
import { usePutRequest } from '../../hooks/request'
import ServerError from '../common/ServerError'
import SubjectForm from './SubjectForm'

export default function SubjectUpdate({ onCancel, onUpdate, employee }) {
    const url = SUBJECT_DETAIL.replace('{id}', employee.id)
    const employeeUpdate = usePutRequest({ url })

    async function onSubmit(data, actions) {
        const { success } = await employeeUpdate.request({
            data,
        })

        if (success) {
            actions.resetForm()
            onUpdate()
        }
    }


    return (
        <div>
            <b>Fanni tahrirlash</b><br /><br />
            <ServerError error={employeeUpdate.error} />
            <SubjectForm
                initialValues={employee}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={employeeUpdate.loading} />
        </div>
    )
}
