/* eslint-disable jsx-a11y/anchor-is-valid */
import isEmpty from 'lodash/isEmpty'
import React, { Fragment, useState } from 'react'
import { usePostRequest } from '../../hooks/request'
import { STUDENT_LIST } from '../../urls'
import CustomerInfoGroupsForm from './CustomerInfoGroupsForm'
import CustomerInfoGroupsItem from './CustomerInfoGroupsItem'

export default function CustomerInfoGroups({ customer, onUpdate, onDelete }) {
    const [showForm, setShowForm] = useState(false)
    const createStudent = usePostRequest({ url: STUDENT_LIST })

    async function onCreate(data) {
        const { response, error } = await createStudent.request({ data: { ...data, customer: customer.id } })
        if (isEmpty(error)) {
            customer.students.push(response)
            onUpdate(customer.id, customer)
        }
    }

    return (
        <Fragment>
            <span className="is-size-5">Группы</span>

            <table className="table is-striped is-fullwidth">
                <tbody>
                    <tr />
                    {customer.students.map((item) => (
                        <CustomerInfoGroupsItem
                            item={item}
                            key={item.id}
                            customer={customer}
                            onUpdate={onUpdate}
                            onDelete={onDelete} />
                    ))}

                    {showForm ? (
                        <CustomerInfoGroupsForm
                            loading={createStudent.loading}
                            customer={customer}
                            onCancel={() => setShowForm(false)}
                            onSubmit={onCreate} />
                    ) : (
                        <tr>
                            <td colSpan="3">
                                <a onClick={() => setShowForm(true)}>
                                    <i className="icon ion-md-add" />
                                    Добавить
                                </a>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Fragment>
    )
}
