import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

export default function StudentTabs() {
    const { path } = useRouteMatch()

    return (
        <div className="tabs">
            <ul>

                <li className={cn({ 'is-active': path.startsWith('/students') })}>
                    <NavLink to="/students">O'quvchilar</NavLink>
                </li>


            </ul>
        </div>
    )
}
