import React from 'react'
import Layout from '../../components/center/Layout'
import { useLoad } from '../../hooks/request'
import { SUBJECT_DATE_TIME_LIST, SUBJECT_LIST } from '../../urls'
import Table from '../../components/common/Table'
import { checkPermission as cp } from '../../utils/auth'
import Button from '../../components/common/Button'
import { useModal } from '../../hooks/modal'
import SettingsTabs from '../../components/center/SettingsTabs'
import Card from '../../components/common/Card'
import HourItem from '../../components/school/HourItem'
import HourCreate from '../../components/school/HourCreate'
import SubjectItem from '../../components/school/SubjectItem'
import SubjectCreate from '../../components/school/SubjectCreate'


export default function SettingsSchool() {
    const employees = useLoad({ url: SUBJECT_DATE_TIME_LIST })

    const [showCreateModal, hideCreateModal] = useModal(
        <HourCreate onSuccess={() => {
            hideCreateModal()
            employees.request()
        }} onCancel={() => hideCreateModal()} />,
    )


    const subjects = useLoad({ url: SUBJECT_LIST })

    const [showCreateModalSubject, hideCreateModalSubject] = useModal(
        <SubjectCreate onSuccess={() => {
            hideCreateModalSubject()
            subjects.request()
        }} onCancel={() => hideCreateModalSubject()} />,
    )

    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <div className="columns">
                <div className="column">
                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Soat va vaqt</div>

                            {cp('staff.add_employee') ? (
                                <div className="column is-narrow">
                                    <Button
                                        onClick={showCreateModal}
                                        text="Qo'shish"
                                        icon="ion-md-add"
                                        className="is-pulled-right is-link is-outlined" />
                                </div>
                            ) : null}
                        </div>

                        <Table
                            loading={employees.loading}
                            totalCount={employees.response ? employees.response.length : 0}
                            items={employees.response ? employees.response : []}
                            columns={{
                                name: 'Имя',
                                actions: '',
                            }}
                            renderItem={(item) => (
                                <HourItem key={item.id} onUpdate={employees.request} onDelete={employees.request}
                                    item={item} />
                            )} />
                    </Card> <br />

                </div>

                <div className="column">
                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Fanlar</div>
                            {cp('academy.add_advert') ? (
                                <div className="column is-narrow">
                                    <Button
                                        onClick={showCreateModalSubject}
                                        text="Qo'shish"
                                        icon="ion-md-add"
                                        className="is-pulled-right is-link is-outlined" />
                                </div>
                            ) : null}
                        </div>

                        {cp('academy.view_advert') ? (
                            <Table
                                loading={subjects.loading}
                                totalCount={subjects.response ? subjects.response.length : 0}
                                items={subjects.response ? subjects.response : []}
                                columns={{
                                    name: 'Имя',
                                    actions: '',
                                }}
                                renderItem={(item) => (
                                    <SubjectItem key={item.id} onUpdate={subjects.request} onDelete={subjects.request}
                                        item={item} />
                                )} />
                        ) : null}
                    </Card>
                </div>
            </div>
        </Layout>
    )
}
