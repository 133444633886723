import React, { Fragment, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import cn from 'classnames'
import moment from 'moment'
import Layout from '../../components/center/Layout'
import { EMPLOYEE_DETAIL, EMPLOYEE_SALARY, SALARY_LIST } from '../../urls'
import { useLoad } from '../../hooks/request'
import Table from '../../components/common/Table'
import { PermissionDenied } from '../../components/center/PermissionDenied'
import { checkPermission as cp } from '../../utils/auth'
import Button from '../../components/common/Button'
import { useModal } from '../../hooks/modal'
import EmployeeSalaryItem from '../../components/center/EmployeeSalaryItem'
import { format } from '../../utils/number'
import EmployeePositions from '../../components/center/EmployeePositions'
import Loader from '../../components/common/Loader'
import EmployeeInvite from '../../components/center/EmployeeInvite'


export default function EmployeeDetail() {
    const { employeeId } = useParams()
    const employeeSalary = useLoad({ url: EMPLOYEE_SALARY.replace('{id}', employeeId) })
    const employeeDetail = useLoad({ url: EMPLOYEE_DETAIL.replace('{id}', employeeId) })
    const employee = employeeDetail.response
    const [page, setPage] = useState(1)

    const [showInviteModal, hideInviteModal] = useModal(
        <EmployeeInvite
            employee={employeeDetail.response}
            onCancel={() => hideInviteModal()}
            onSuccess={() => {
                hideInviteModal()
                employeeDetail.request()
            }} />,
    )

    const transactions = useLoad({
        url: SALARY_LIST.replace('{employee_id}', employeeId),
        params: { employee: employeeId, page },
    }, [page])

    if (!cp('staff.view_employee')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <NavLink className="has-text-black button is-white" to="/staff/employee">
                <i className="icon ion-md-arrow-back is-size-4" />
            </NavLink>

            <span className="is-size-4">
                Xodimlar haqida ma'lumot : {employee ? employee.name : ''}
            </span>


            {!employeeDetail.loading && employee ? (
                <div>
                    {cp('staff.add_employeeinvitation') && !employee.user && !employee.invited ? (
                        <article className="message is-warning">
                            <div className="message-body">
                                <b>Ushbu xodim tizimda ro'yxatdan o'tmagan!</b><br />
                                Taklif qilish uchun quyidagi tugmani bosing va xodimning elektron pochta manzilini
                                kiriting.
                                Biz unga shaxsiy tezkor ro'yxatdan o'tish sahifasiga havola bilan elektron pochta
                                xabarini yuboramiz.<br />
                                <br />

                                <Button
                                    text="Пригласить"
                                    icon="ion-md-person-add"
                                    onClick={showInviteModal}
                                    className="is-primary is-small" />
                            </div>
                        </article>
                    ) : null}

                    <hr />
                    <div className="columns">
                        <b className="column">TO'LIQ ISM:</b>
                        <span className="column">{employee.name}</span>

                        <b className="column">O'qituvchi ulushi: </b>
                        <span className="column">{employee.academyShare}%</span>
                    </div>

                    <div className="columns">
                        <b className="column">Tug'ilgan kun: </b>
                        <span className="column">{employee.birthday}</span>

                        <b className="column">Sotish ulushi: </b>
                        <span className="column">{employee.sellerShare}%</span>
                    </div>

                    <div className="columns">
                        <b className="column">Lavozim: </b>
                        <span className="column">
                            <EmployeePositions positions={employee.positions} />
                        </span>

                        <b className="column">Belgilangan ish haqi: </b>
                        <span className="column">{format(employee.salary)} So'm</span>
                    </div>

                    <div className="columns">
                        <b className="column is-3">Tizim foydalanuvchisi: </b>
                        <div className="column">
                            {employee.user ? employee.user.email : (
                                <Fragment>
                                    <i className="has-text-grey">
                                        {employee.invited ? 'Taklif qilindi' : 'bog\'lanmagan'}
                                    </i> &nbsp;

                                    {cp('staff.add_employeeinvitation') ? (
                                        <Button
                                            text={employee.invited ? 'Qayta taklif qiling' : 'Taklif qiling'}
                                            icon="ion-md-person-add"
                                            onClick={showInviteModal}
                                            className={cn('is-small', employee.invited ? 'is-warning' : 'is-link')} />
                                    ) : null}
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            ) : <Loader large center />}
            <hr />

            <span className="is-size-4">Ish haqi tarixi</span>

            <Table
                loading={employeeSalary.loading}
                totalCount={employeeSalary.response ? employeeSalary.response.length : 0}
                pageSize={employeeSalary.response ? employeeSalary.response.length : 0}
                items={employeeSalary.response ? employeeSalary.response : []}
                columns={{ name: 'Oy', salary: 'Ish haqi', actions: '' }}
                renderItem={(item) => (
                    <EmployeeSalaryItem key={item.month} item={item} employee={employee} />
                )} />

            <br />

            <span className="is-size-4">
                To'lov tarixi
            </span>

            {cp('finance.view_salary') ? (
                <Table
                    loading={transactions.loading}
                    totalCount={transactions.response ? transactions.response.count : 0}
                    items={transactions.response ? transactions.response.results : []}
                    columns={{ id: '№', amount: 'So\'m', wallet: 'Hamyon', createdAt: 'Yaratilgan sana' }}
                    activePage={page}
                    onPageChange={setPage}
                    renderItem={(transaction) => (
                        <tr key={transaction.id}>
                            <td>{transaction.id}</td>

                            <td className={transaction.amount > 0 ? 'has-text-success' : 'has-text-danger'}>
                                {format(transaction.amount)}
                            </td>

                            <td>{transaction.wallet.name}</td>
                            <td>{moment(transaction.createdAt).calendar()}</td>
                        </tr>
                    )} />
            ) : null}
        </Layout>
    )
}
