import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Layout from '../../components/center/Layout'
import { useLoad } from '../../hooks/request'
import { EMPLOYEE_LIST } from '../../urls'
import { useQueryParams } from '../../hooks/queryString'
import { useModal } from '../../hooks/modal'
import EmployeeCreate from '../../components/center/EmployeeCreate'
import Button from '../../components/common/Button'
import Table from '../../components/common/Table'
import EmployeeItem from '../../components/center/EmployeeItem'
import { checkPermission as cp } from '../../utils/auth'
import { PermissionDenied } from '../../components/center/PermissionDenied'
import CustomersFilter from '../../components/center/CustomersFilter'
import EmployeeTabs from '../../components/center/EmployeeTabs'

export default function Employee() {
    const params = useQueryParams()
    const [page, setPage] = useState(1)
    const employees = useLoad({
        url: EMPLOYEE_LIST,
        params: { page, ...params },
    }, [params, page])

    const [showCreateModal, hideCreateModal] = useModal(
        <EmployeeCreate onSuccess={() => {
            hideCreateModal()
            employees.request()
        }} onCancel={() => hideCreateModal()} />,
    )

    if (!cp('staff.view_employee')) {
        return <PermissionDenied />
    }


    return (
        <Layout>
            <EmployeeTabs />
            <div className={cn('columns', css(styles.searchBar))}>
                <div className="column is-4 is-offset-8">
                    <CustomersFilter className={css(styles.search)} />
                </div>
            </div>


            <div className={cn('columns', css(styles.searchBar))}>
                <div className="column is-4 is-offset-8">
                    <div className={css(styles.button)}>
                        <Button
                            onClick={showCreateModal}
                            text="Qo'shish"
                            icon="ion-md-add"
                            className="is-link is-outlined is-pulled-right mb-2" />
                    </div>

                </div>
            </div>

            <br />

            <div className={css(styles.table)}>
                <div className={css(styles.tableItem)}>
                    <Table
                        activePage={page}
                        onPageChange={setPage}
                        loading={employees.loading}
                        totalCount={employees.response ? employees.response.count : 0}
                        items={employees.response ? employees.response.results : []}
                        columns={{
                            name: 'Ism',
                            email: 'Elektron pochta va parol',
                            birthday: 'Tug\'ilgan kun',
                            salary: 'Ish haqi',
                            position: 'Lavozim',
                            actions: '',
                        }}
                        renderItem={(item) => (
                            <EmployeeItem key={item.id} onUpdate={employees.request} onDelete={employees.request}
                                item={item} />
                        )} />
                </div>
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    column: {
        marginRight: '10px',
    },
    searchBar: {
        '@media (min-width: 900px)': {
            height: '0',
        },
    },
    search: {
        '@media (min-width: 900px)': {
            marginTop: '-4rem',
        },
    },
    button: {
        '@media (min-width: 900px)': {
            marginTop: '-1rem',
        },
    },

    table: {
        '@media (max-width: 500px)': {
            overflowX: 'scroll',
        },
    },
    tableItem: {
        '@media (max-width: 500px)': {
            width: 800,
        },
    },
    br: {
        '@media (max-width: 500px)': {
            width: 800,
        },
    },
})
