import { Form, Formik } from 'formik'
import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { required } from '../../utils/validators'
import Button from '../common/Button'
import Select from '../common/Select'
import { useLoad } from '../../hooks/request'
import { EMPLOYEE_SIMPLE_LIST } from '../../urls'
import Input from '../common/Input'
import { groups } from '../../utils/position'
import CustomSelect from '../common/CustomSelect'

export default function GroupForm({ onSubmit, onCancel, loading, initialValues }) {
    const employee = useLoad({ url: EMPLOYEE_SIMPLE_LIST, params: { size: 100 } })
    const options = Object.entries(groups).map(([id, name]) => ({ id, name }))

    const hourItem = employee.response ? employee.response.results : []
    const hourItemOptions = Object.entries(hourItem).map(([, {
        id,
        name,
    }]) => ({
        id,
        name: id,
        label: name,
    }))

    return (
        <Formik initialValues={{
            name: '',
            type: '',
            price: 600000,
            ...initialValues,
            leadTeacher: initialValues && !isEmpty(initialValues.leadTeacher) ? {
                label: initialValues.leadTeacher.name,
                name: initialValues.leadTeacher.id,
                id: initialValues.leadTeacher.id,
            } : '',
        }} onSubmit={onSubmit}>
            <Form>

                <Input
                    name="name"
                    label="Sinf yoki kursning nomi"
                    placeholder="Sinf: 7A  yoki Kurs: Matematika"
                    validate={required} />
                <Select
                    name="type"
                    options={options}
                    label="Tip"
                    validate={required} />
                <CustomSelect
                    className="mb-3"
                    name="leadTeacher"
                    options={hourItemOptions}
                    label="Sinf rahbari"
                    loading={employee.loading}
                    validate={required} />


                <Button
                    loading={loading}
                    text="Saqlash"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Bekor qilish"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
