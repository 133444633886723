/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import cn from 'classnames'
import React, { useState } from 'react'
import { checkPermission as cp } from '../../utils/auth'
import { useModal } from '../../hooks/modal'
import { useDeleteRequest, usePutRequest } from '../../hooks/request'
import { ROOM_DETAIL, ROOM_CHANGE_MAIN } from '../../urls'
import { useMessage } from '../../hooks/message'
import RoomUpdate from './RoomUpdate'
import Loader from '../common/Loader'


export default function RoomItem({ room, onUpdate, onDelete }) {
    const deleteRoom = useDeleteRequest()
    const [showMessage] = useMessage()
    const changeMainRoom = usePutRequest()
    const [showDropdown, setShowDropdown] = useState(false)

    async function changeMain() {
        await changeMainRoom.request({ url: ROOM_CHANGE_MAIN.replace('{id}', room.id) })
        onUpdate()
    }

    async function deleteColumn() {
        if (global.confirm('Haqiqatan ham oʻchirib tashlamoqchimisiz?')) {
            const { error } = await deleteRoom.request({ url: ROOM_DETAIL.replace('{id}', room.id) })

            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }
            onDelete()
        }
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <RoomUpdate room={room} onSuccess={async () => {
            await onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    return (
        <tr>
            <td>
                {room.name}<span className="is-size-7 has-text-success">{room.isMain ? ' (основной)' : ''}</span>
            </td>

            <td>
                <div className="is-pulled-right">
                    {cp('academy.delete_room') ? (
                        !deleteRoom.loading ? (
                            <i onClick={deleteColumn} className="icon pointer ion-md-trash" />
                        ) : <Loader className="icon" />
                    ) : null}

                    {cp('academy.change_room') ? (
                        <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                    ) : null}
                    <div
                        className={cn('dropdown', { 'is-active': showDropdown })}
                        onMouseLeave={() => setShowDropdown(false)}>
                        <div className="dropdown-trigger">
                            <i className="icon ion-md-more" onClick={() => setShowDropdown(!showDropdown)} />
                        </div>

                        <div className="dropdown-menu" id="dropdown-menu">
                            <div className="dropdown-content">
                                {cp('finance.change_wallet') && !room.isMain ? (
                                    <a className="dropdown-item" onClick={changeMain}>
                                        <i className="icon ion-md-checkmark" />
                                        Сделать главным
                                    </a>
                                ) : (
                                    <a className="dropdown-item">
                                        <i className="icon ion-md-checkmark" />
                                        Главный
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    )
}
