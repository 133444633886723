import React from 'react'
import Layout from '../../components/center/Layout'
import SettingsTabs from '../../components/center/SettingsTabs'
import { useLoad } from '../../hooks/request'
import { WALLET_LIST, TRANSACTION_CATEGORY_LIST } from '../../urls'
import WalletCreate from '../../components/center/WalletCreate'
import { useModal } from '../../hooks/modal'
import Button from '../../components/common/Button'
import { checkPermission as cp } from '../../utils/auth'
import Table from '../../components/common/Table'
import WalletSettingsItem from '../../components/center/WalletSettingsItem'
import Card from '../../components/common/Card'
import { useQueryParams } from '../../hooks/queryString'
import TransactionCategoryCreate from '../../components/center/TransactionCategoryCreate'
import TransactionCategoryItem from '../../components/center/TransactionCategoryItem'


export default function SettingsFinance() {
    const wallets = useLoad({ url: WALLET_LIST })
    const params = useQueryParams()

    const [showCreteWallet, hideCreateWallet] = useModal(
        <WalletCreate wallets={wallets} onSuccess={() => {
            wallets.request()
            hideCreateWallet()
        }} onCancel={() => hideCreateWallet()} />,
    )

    const transactionCategories = useLoad({
        url: TRANSACTION_CATEGORY_LIST,
        params,
    }, [params.page])

    const [showCreateTransactionCategory, hideCreateTransactionCategory] = useModal(
        <TransactionCategoryCreate onCancel={() => hideCreateTransactionCategory()} onSuccess={async () => {
            await transactionCategories.request()
            hideCreateTransactionCategory()
        }} />,
    )

    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <div className="columns">
                {cp('finance.view_wallet') ? (
                    <div className="column">
                        <Card>
                            <div className="columns">
                                <div className="is-size-4 column">Счета</div><br />

                                <div className="buttons has-addons is-right">
                                    {cp('finance.add_wallet') ? (
                                        <Button
                                            onClick={showCreteWallet}
                                            text="Создать счёт"
                                            icon="ion-md-add"
                                            className="is-centered is-link is-outlined" />
                                    ) : null}
                                </div>
                            </div>

                            <Table
                                totalCount={2}
                                columns={{ id: '№', name: 'Имя', icon: 'Иконка', actions: '' }}
                                loading={wallets.loading}
                                items={wallets.response ? wallets.response.results : []}
                                renderItem={(item) => (
                                    <WalletSettingsItem key={item.id} item={item} wallets={wallets} />
                                )} />
                        </Card>
                    </div>
                ) : null}

                {cp('finance.view_transactioncategory') ? (
                    <div className="column">
                        <Card>
                            <div className="columns">
                                <div className="is-size-4 column">Категория транзакций</div><br />

                                <div className="buttons has-addons is-right">
                                    {cp('finance.add_transactioncategory') ? (
                                        <Button
                                            onClick={showCreateTransactionCategory}
                                            text="Добавить категорию"
                                            icon="ion-md-add"
                                            className="is-centered is-link is-outlined" />
                                    ) : null}
                                </div>
                            </div>

                            <Table
                                totalCount={2}
                                loading={transactionCategories.loading}
                                columns={{ name: 'Название', type: 'Тип', actions: '' }}
                                items={transactionCategories.response ? transactionCategories.response.results : []}
                                renderItem={(category) => (
                                    <TransactionCategoryItem
                                        category={category}
                                        onUpdate={transactionCategories.request}
                                        onDelete={transactionCategories.request}
                                        key={category.id} />
                                )} />
                        </Card>
                    </div>
                ) : null}
            </div>
        </Layout>
    )
}
