import { Form, Formik } from 'formik'
import React from 'react'
import isEmpty from 'lodash/isEmpty'
import Button from '../common/Button'
import { useLoad } from '../../hooks/request'
import { SUBJECT_LIST } from '../../urls'
import CustomSelect from '../common/CustomSelect'
import { required } from '../../utils/validators'

export default function TimeTableSubjectForm({ onSubmit, onCancel, loading, initialValues }) {
    const group = useLoad({ url: SUBJECT_LIST, params: { size: 100 } })
    const groupItem = group.response ? group.response : []
    const employeeOptions = Object.entries(groupItem).map(([, {
        id,
        name,
    }]) => ({
        id,
        name: id,
        label: name,
    }))
    return (
        <Formik initialValues={{

            ...initialValues,
            subject: initialValues && !isEmpty(initialValues.subject) ? {
                label: initialValues.subject.name,
                name: initialValues.subject.id,
                id: initialValues.subject.id,
            } : '',
        }} onSubmit={onSubmit}>
            <Form>


                <CustomSelect
                    name="subject"
                    options={employeeOptions}
                    label="Fanni tanlang"
                    loading={group.loading}
                    validate={required} />


                <Button
                    loading={loading}
                    text="Saqlash"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Bekor qilish"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
