import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { currency } from '../../utils/currency'
import { getMonth } from '../../utils/date'
import { format } from '../../utils/number'

export default function WalletItem({ wallet }) {
    const currencyName = currency(wallet.currency)

    return (
        <NavLink
            to={`/finance/wallet/${wallet.id}/transaction`}
            className="column is-4-widescreen is-6-desktop is-12-tablet">
            <div className={cn(css(styles.height), 'box')}>
                <div className="columns">
                    <div className="column is-narrow">
                        <div className={cn('icon has-text-grey-light', wallet.icon, css(styles.icon))} />
                    </div>

                    <div className="column">
                        <b>{wallet.name || <span className="is-italic has-text-grey">ko'rsatilmagan</span>}</b>

                        <p>
                            Balans: &nbsp;
                            <span className="has-text-info">
                                {format(wallet.balance || 0)} {currencyName}
                            </span>
                        </p>

                        <p>Kelgani ({getMonth()}): &nbsp;
                            <span className="has-text-success">
                                {format(wallet.income || 0)} {currencyName}
                            </span>
                        </p>

                        <p>Ketgani ({getMonth()}): &nbsp;
                            <span className="has-text-danger">
                                {format(wallet.outcome || 0)} {currencyName}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </NavLink>
    )
}


const styles = StyleSheet.create({
    icon: {
        fontSize: '3em',
        margin: '1rem 0.5rem',
    },
    height: {
        height: '100%',
    },
})
