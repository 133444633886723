import React from 'react'
import { tags } from './CustomerType'
import CustomerActions from './CustomerActions'
import { useModal } from '../../hooks/modal'
import CustomerUpdate from './CustomerUpdate'
import CustomerInfo from './CustomerInfo'
import CustomerGroups from './CustomerGroups'
import Dropdown, { DropdownItem } from '../common/Dropdown'
import { CUSTOMER_DETAIL, CUSTOMER_MOVE } from '../../urls'
import { usePutRequest } from '../../hooks/request'
import Loader from '../common/Loader'

export default function CustomerTableItem({ customer, status, statusList, showLabels, onUpdate, onDelete, type }) {
    const url = CUSTOMER_DETAIL.replace('{id}', customer.id)
    const customerUpdate = usePutRequest({ url })

    const [showUpdateModal, hideUpdateModal] = useModal(
        <CustomerUpdate
            customer={customer}
            onCancel={() => hideUpdateModal()}
            onSuccess={(values) => {
                onUpdate(customer.id, values)
                hideUpdateModal()
            }} />,
    )

    const [showInfoModal, hideInfoModal] = useModal(
        <CustomerInfo
            onCancel={() => hideInfoModal()}
            onChange={() => {
                hideInfoModal()
                showUpdateModal()
            }}
            customer={customer}
            onUpdate={(newCustomerId, newCustomer) => {
                // TODO
                onUpdate(newCustomerId, newCustomer)
                hideInfoModal()
            }}
            onDelete={(newCustomerId, newCustomer) => {
                onUpdate(newCustomerId, newCustomer)
                hideInfoModal()
            }} />,
    )

    async function changeStatus(state) {
        await customerUpdate.request({
            url: CUSTOMER_MOVE.replace('{id}', customer.id),
            data: { state, position: 0 },
        })
        onUpdate(customer.id, { state })
    }

    return (
        <tr key={customer.id}>
            <td onClick={showInfoModal} className="pointer">
                {customer.name} &nbsp;
                {showLabels ? tags[customer.type] : null}
            </td>


            <td>
                {customerUpdate.loading && status ? <Loader /> : (
                    <Dropdown trigger={(
                        <div style={{ background: status.color }} className="tag has-text-white pointer">
                            {status.title}
                            <i className="icon ion-md-arrow-dropdown" />
                        </div>
                    )}>
                        {statusList.map((item) => (
                            <DropdownItem onClick={() => changeStatus(item.id)} key={item.id} text={item.title} />
                        ))}
                    </Dropdown>
                )}
            </td>

            <td><CustomerGroups customer={customer} /></td>

            <td>
                <CustomerActions
                    customer={customer}
                    onDelete={onDelete}
                    onTypeChanged={onUpdate}
                    onClickUpdate={showUpdateModal}
                    paramsType={type} />
            </td>
        </tr>
    )
}
