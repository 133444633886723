import React, { Fragment, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import cn from 'classnames'
import Layout from '../../components/center/Layout'
import { INVOICE_LIST, SCHOOL_STUDENT_DETAIL } from '../../urls'
import { useLoad } from '../../hooks/request'
import Table from '../../components/common/Table'
import { checkPermission as cp } from '../../utils/auth'
import Button from '../../components/common/Button'
import { useModal } from '../../hooks/modal'
import Loader from '../../components/common/Loader'
import EmployeeInvite from '../../components/center/EmployeeInvite'
import StudentInvoiceItem from '../../components/school/StudentInvoiceItem'


export default function StudentDetail() {
    const { studentId } = useParams()
    const history = useHistory()
    const [page, setPage] = useState(1)
    const employeeSalary = useLoad({ url: INVOICE_LIST, params: { student: studentId, page } }, [page])
    const employeeDetail = useLoad({ url: SCHOOL_STUDENT_DETAIL.replace('{studentId}', studentId) })
    const employee = employeeDetail.response


    const [showInviteModal, hideInviteModal] = useModal(
        <EmployeeInvite
            employee={employeeDetail.response}
            onCancel={() => hideInviteModal()}
            onSuccess={() => {
                hideInviteModal()
                employeeDetail.request()
            }} />,
    )

    return (
        <Layout>
            <Button
                onClick={() => history.push('/students')}
                icon="ion-md-arrow-back"
                className="is-link is-outlined mr-2" />

            <span className="is-size-4">
                O'quvchining ma'lumotlari: {employee ? employee.name : ''}
            </span>


            {!employeeDetail.loading && employee ? (
                <div>
                    {/* {cp('staff.add_employeeinvitation') && !employee.user && !employee.invited ? (
                        <article className="message is-warning">
                            <div className="message-body">
                                <b>Данный сотрудник не зарегистрирован в системе!</b><br />
                                Что пригласить нажмите на кнопку наже и введите емейл сотрудника.
                                Мы отправим ему(ей) писмо с ссылкой на приватную страницу быстрой регистрации.<br />
                                <br />

                                <Button
                                    text="Пригласить"
                                    icon="ion-md-person-add"
                                    onClick={showInviteModal}
                                    className="is-primary is-small" />
                            </div>
                        </article>
                    ) : null} */}

                    <hr />
                    <div className="columns">
                        <b className="column">F.I.O: </b>
                        <span className="column">{employee.name}</span>

                        <b className="column">Manzil: </b>
                        <span className="column">{employee.address}</span>
                    </div>

                    <div className="columns">
                        <b className="column">Tug'ilgan sana: </b>
                        <span className="column">{employee.birthday}</span>

                        <b className="column">Ota onasing ismi: </b>
                        <span className="column">{employee.parents}</span>
                    </div>

                    <div className="columns">
                        <b className="column">Sinfi va kursi: </b>
                        <span className="column">
                            {employee.group.map((item) => (
                                <span key={item} className="tag is-medium is-light is-link mr-2 mb-2">
                                    {item.name}
                                </span>

                            ))}
                        </span>

                        <b className="column">Telefon raqam: </b>
                        <span className="column">{employee.phoneNumber}</span>
                    </div>

                    <div className="columns">
                        <b className="column is-3">Tizim foydalanuvchisi: </b>
                        <div className="column">
                            {employee.user ? employee.user.email : (
                                <Fragment>
                                    <i className="has-text-grey">
                                        {employee.invited ? 'Taklif qilindi' : 'bog\'lanmagan'}
                                    </i> &nbsp;

                                    {cp('staff.add_employeeinvitation') ? (
                                        <Button
                                            text={employee.invited ? 'Qayta taklif qiling' : 'Taklif qiling'}
                                            icon="ion-md-person-add"
                                            onClick={showInviteModal}
                                            className={cn('is-small', employee.invited ? 'is-warning' : 'is-link')} />
                                    ) : null}
                                </Fragment>
                            )}
                        </div>
                        <b className="column">Telegram username: </b>
                        <span className="column">@{employee.telegramUsername}</span>
                    </div>
                </div>
            ) : <Loader large center />}
            <hr />

            <span className="is-size-4">To'lovlar tarixi</span>

            <Table
                onPageChange={setPage}
                activePage={page}
                loading={employeeSalary.loading}
                totalCount={employeeSalary.response ? employeeSalary.response.count : 0}
                pageSize={15}
                items={employeeSalary.response ? employeeSalary.response.results : []}
                columns={{
                    name: 'Oy',
                    group: 'Maktab yoki kurs',
                    salary: 'To\'lov summasi',
                    date: 'To\'lov vaqti',
                    status: 'To\'lov holati',
                    // actions: '',
                }}
                renderItem={(item) => (
                    <StudentInvoiceItem key={item.month} item={item} employee={employee} />
                )} />

            <br />
        </Layout>
    )
}
