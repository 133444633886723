import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../../utils/validators'
import Input from '../common/Input'
import Button from '../common/Button'
import MultiSelect from '../common/MultiSelect'
import { useLoad } from '../../hooks/request'
import { SCHOOL_GROUP_LIST } from '../../urls'
import Checkbox from '../common/Checkbox'

export default function StudentForm({ onSubmit, onCancel, loading, initialValues }) {
    const groups = useLoad({ url: SCHOOL_GROUP_LIST, params: { size: 50 } })
    const groupItem = groups.response ? groups.response.results : []
    const options = Object.entries(groupItem).map(([, {
        id, name,
    }]) => ({
        id, name: id, label: name, value: id,
    }))
    return (
        <Formik initialValues={{
            name: '',
            phoneNumber: '',
            phoneNumberSecond: '',
            address: '',
            parents: '',
            price: 0,
            isBus: false,
            isLunch: false,
            isFreedom: false,
            birthday: '',
            group: [],
            ...initialValues,
        }} onSubmit={onSubmit}>
            <Form>
                <Input
                    label="Ism"
                    name="name"
                    disabled={loading}
                    validate={required}
                    placeholder="Talaba ismini kiriting" />

                <MultiSelect
                    validate={required}
                    label="Sinf yoki kurslarini tanlang"
                    name="group"
                    placeholder="O'quvchi sinf va kurslarini tanlang"
                    options={options} />


                <Input
                    name="birthday"
                    type="date"
                    label="Tug'ilgan kun"
                    disabled={loading}
                    validate={required}
                    placeholder="Talabaning tug'ilgan kunini kiriting" />

                <Input
                    label="Otasining telefon raqami"
                    name="phoneNumber"
                    disabled={loading}
                    validate={required}
                    placeholder="O'quvchi otasining telefon qaramini kiriting" />

                <Input
                    label="Onasining telefon raqami"
                    name="phoneNumberSecond"
                    disabled={loading}
                    help="Telefon raqami bo'lmasa kiritish shart emas"
                    placeholder="O'quvchi onasining telefon raqamini kiriting" />

                <Checkbox style={{ width: 17, height: 17 }} label="Avtobus borish bormasligi" name="isBus" />
                <Checkbox style={{ width: 17, height: 17 }} label="Ovqatlanishi yoki ovqatlanmasligi" name="isLunch" />
                <Checkbox style={{ width: 17, height: 17 }} label="Imtiyozga yoki ega emas" name="isFreedom" />

                <Input
                    type="number"
                    label="Umumiy oylik summa"
                    name="price"
                    validate={required}
                    placeholder="O'quvchining oylik to'lov miqdorini kiriting" />

                <Input
                    label="Manzil"
                    name="address"
                    disabled={loading}
                    validate={required}
                    placeholder="O'quvchining manzilini kiriting" />

                <Input
                    label="Ota onasinig ismi"
                    name="parents"
                    disabled={loading}
                    validate={required}
                    placeholder="O'quvchining ota onasining ismi kiriting" />


                <Button
                    loading={loading}
                    text="Saqlash"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Bekor qilish"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
