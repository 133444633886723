import React from 'react'
import Button from '../common/Button'
import { checkPermission as cp } from '../../utils/auth'
import Loader from '../common/Loader'
import { useModal } from "../../hooks/modal";
import TimeTableSubjectUpdate from "./TimeTableSubjectUpdate";
import { useDeleteRequest } from "../../hooks/request";
import { TIMETABLE_SUBJECT_DETAIL } from "../../urls";
import { useMessage } from "../../hooks/message";


export default function TimeTableSubjectItem({ item, index, timetableSubject, onDelete, assessment }) {

    const [showUpdateModal, hideUpdateModal] = useModal(
        <TimeTableSubjectUpdate
            employee={item}
            timetableId={assessment?.id}
            onCancel={() => hideUpdateModal()}
            onUpdate={() => {
                timetableSubject.request()
                hideUpdateModal()
            }}/>,
    )


    const employeeDelete = useDeleteRequest({ url: TIMETABLE_SUBJECT_DETAIL.replace('{id}', item.id) })
    const [showMessage] = useMessage()

    async function deleteGroup() {
        if (global.confirm('Haqiqatan ham oʻchirib tashlamoqchimisiz?')) {
            const { error } = await employeeDelete.request()
            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }
            onDelete()
        }
    }

    return (
        <div className="level is-flex">
            <div className="is-left">
                <p>
                    {index + 1}: &nbsp;
                    <span className="has-text-info">
                        {item.subject?.name}
                    </span>
                </p>
            </div>
            <div className="is-right">
                <div className="is-flex">
                    {cp('staff.delete_employee') ? (
                        !timetableSubject.loading ? (
                            <Button
                                onClick={deleteGroup}
                                icon="ion-md-trash"
                                className="is-danger is-outlined mr-2 is-small"/>
                        ) : <Loader className="icon"/>
                    ) : null}
                    {cp('staff.change_employee') ? (
                        <Button
                            onClick={showUpdateModal}
                            icon="ion-md-create"
                            className="is-success is-outlined mb-2 is-small"/>
                    ) : null}
                </div>
            </div>
        </div>
    )
}
