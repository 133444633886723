import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React from 'react'
import { isEmpty } from 'lodash'
import { useLoad } from "../../hooks/request";
import { GROUP_TIMETABLE } from "../../urls";


export default function TimeTableGroupItem({ groupId, date }) {
    const timetable = useLoad({ url: GROUP_TIMETABLE.replace('{groupId}', groupId), params:{date} }, [date])
    const wallet = timetable.response ? timetable.response : {}

    return (
        <div className="column is-4-widescreen is-6-desktop is-12-tablet">
            <div className={cn(css(styles.height), 'box')}>
                <div className="columns">
                    <div style={{ marginTop: '-1.5rem' }} className="column">

                        <div className="column is-flex is-justify-content-center">
                            <div
                                className={cn('icon has-text-grey-light', css(styles.icon))}>  {wallet?.day}
                            </div>
                        </div>
                        {!isEmpty(wallet?.subjects) && wallet?.subjects ? wallet?.subjects.map((item, index) => (
                            <p>
                               {index + 1}: &nbsp;
                                <span className="has-text-info">
                                    {item}
                                </span>
                            </p>
                        )) : (
                            <div ref={wallet.ref} className="has-text-grey-light is-italic has-text-centered">
                                {!wallet.subjects && !wallet.loading && wallet.length !== 0   ? 'Jadvalga darslar qo\'shilmagan' : ''}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}


const styles = StyleSheet.create({
    icon: {
        fontSize: '2em',
        margin: '2rem 1rem',
    },
    height: {
        height: '100%',
    },
    buttonAction: {
        '@media (max-width: 500px)': {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    titleHeader: {
        '@media (max-width: 500px)': {
            textAlign: 'center',
        },
    },

})
