/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import cn from 'classnames'
import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'
import Layout from '../../components/center/Layout'
import { GROUPS_DETAIL, STATUS_LIST, STUDENT_LIST } from '../../urls'
import { useLoad } from '../../hooks/request'
import Table from '../../components/common/Table'
import StudentItem from '../../components/school/StudentItem'
import Loader from '../../components/common/Loader'
import { useQueryParams } from '../../hooks/queryString'


export default function StudentsList() {
    const { groupId } = useParams()
    const params = useQueryParams()
    const group = useLoad({ url: GROUPS_DETAIL.replace('{id}', groupId) })
    const history = useHistory()
    const status = useLoad({ url: STATUS_LIST })
    const statusList = status.response ? status.response.results : []
    const students = useLoad({
        url: STUDENT_LIST,
        params: { group: groupId, state: params.state, page: params.page },
    }, [])

    async function setUrl(key, value) {
        const val = params[key] !== String(value) ? value : undefined
        history.push(`?${queryString.stringify({ ...params, [key]: val })}`)
    }

    return (
        <Layout>
            {!(students.loading || group.loading) ? (
                <Fragment>
                    <NavLink className="button is-white" to="/academy/group">
                        <i className="icon ion-md-arrow-back is-size-4" />
                    </NavLink> &nbsp;

                    <span className="is-size-4">Студенты группы - {group.response ? group.response.name : ''}</span>
                    <br /><br />
                    <div className="columns">
                        <span
                            className="tag has-background-light-gray pointer"
                            onClick={() => history.push(`/academy/group/${groupId}/students-list`)}>
                            {isEmpty(params)
                                ? <i className={cn('icon ion-md-checkmark')} />
                                : null}
                            Все
                        </span>&nbsp;

                        {statusList.map((item) => (
                            <div key={item.id}>
                                <span style={{ background: item.color }} className="tag pointer"
                                    onClick={() => setUrl('state', item.id)}>
                                    {params.state === `${item.id}` ? (
                                        <i className="icon ion-md-checkmark" />
                                    ) : null}
                                    {item.title}
                                </span>&nbsp;
                            </div>
                        ))}
                    </div>

                    <Table
                        loading={students.loading || group.loading}
                        columns={{
                            student: 'Имя',
                            phone: 'Телефон',
                            totalPiad: 'Оплачено',
                            monthPaid: 'Опл. в этом месяце',
                            balance: 'Баланс',
                            actions: '',
                        }}
                        items={students.response ? students.response.results : []}
                        totalCount={students.response ? students.response.count : 0}
                        renderItem={(item) => (
                            <StudentItem
                                key={item.id}
                                onSuccess={students.request}
                                onPaymentCreate={students.request}
                                item={item} />
                        )} />
                </Fragment>
            ) : <Loader large center />}
        </Layout>
    )
}
