import React from 'react'
import map from 'lodash/map'
import { INVOICE_DETAIL } from '../../urls'
import { usePutRequest } from '../../hooks/request'
import ServerError from '../common/ServerError'
import InvoiceForm from './InvoiceForm'

export default function InvoiceUpdate({ onCancel, onUpdate, employee }) {
    const url = INVOICE_DETAIL.replace('{id}', employee.id)
    const employeeUpdate = usePutRequest({ url })

    async function onSubmit(data, actions) {
        console.log(data)
        const { success } = await employeeUpdate.request({
            data: { ...data, student: data.student.id, group: map(data.group, 'value') },
        })

        if (success) {
            actions.resetForm()
            onUpdate()
        }
    }

    const initialValues = {
        ...employee,
        group: Object.entries(employee.group).map(([, {
            id, name,
        }]) => ({
            id, name: id, label: name, value: id,
        })),
    }


    return (
        <div>
            <b>To'lovni tahrirlash</b><br /><br />
            <ServerError error={employeeUpdate.error} />
            <InvoiceForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={employeeUpdate.loading} />
        </div>
    )
}
