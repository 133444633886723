import React from 'react'
import { usePostRequest } from '../../hooks/request'
import { ASSESSMENT_LIST } from '../../urls'
import AssessmentForm from './AssessmentForm'
import ServerErrorCustom from '../common/ServerErrorCustom'

export default function AssessmentCreate({ onCancel, onSuccess, groupId, groupName }) {
    const assessmentCreate = usePostRequest({ url: ASSESSMENT_LIST })

    async function onSubmit(data, actions) {
        const { success } = await assessmentCreate.request({
            data: {
                ...data,
                group: groupId,
                teacher: data.teacher.name,
                subject: data.subject.name,
                hour: data.hour.name,
            },
        })
        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <b>Yangi dars qo'shing {groupName}</b><br /><br />
            <ServerErrorCustom error={assessmentCreate.error} />
            <AssessmentForm onSubmit={onSubmit} onCancel={onCancel} loading={assessmentCreate.loading} />
        </div>
    )
}
