import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Layout from '../../components/center/Layout'
import { useLoad } from '../../hooks/request'
import { SCHOOL_STUDENT_LIST } from '../../urls'
import { useQueryParams } from '../../hooks/queryString'
import { useModal } from '../../hooks/modal'
import Button from '../../components/common/Button'
import Table from '../../components/common/Table'
import StudentItem from '../../components/school/StudentItem'
import StudentCreate from '../../components/school/StudentCreate'
import CustomersFilter from '../../components/center/CustomersFilter'
import StudentTabs from '../../components/center/StudentTabs'

export default function Students() {
    const params = useQueryParams()
    const history = useHistory()
    const [page, setPage] = useState(1)
    const employees = useLoad({ url: SCHOOL_STUDENT_LIST, params: { ...params, page } }, [page])

    const [showCreateModal, hideCreateModal] = useModal(
        <StudentCreate onSuccess={() => {
            hideCreateModal()
            employees.request()
        }} onCancel={() => hideCreateModal()} />,
    )

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ search })}`)
    }


    return (
        <Layout>

            <StudentTabs />
            <div className={cn('columns', css(styles.searchBar))}>
                <div className="column is-4 is-offset-8">
                    <CustomersFilter className={css(styles.search)} />
                </div>
            </div>


            <div className={cn('columns', css(styles.searchBar))}>
                <div className="column is-4 is-offset-8">
                    <div className={css(styles.button)}>
                        <Button
                            onClick={showCreateModal}
                            text="Qo'shish"
                            icon="ion-md-add"
                            className="is-link is-outlined is-pulled-right mb-2" />
                    </div>

                </div>
            </div>
            <br />
            <div className={css(styles.table)}>
                <div className={css(styles.tableItem)}>
                    <Table
                        onPageChange={setPage}
                        activePage={page}
                        loading={employees.loading}
                        totalCount={employees.response ? employees.response.count : 0}
                        items={employees.response ? employees.response.results : []}
                        columns={{
                            index: '№',
                            name: 'Ism',
                            birthday: 'Tug\'ilgan kun',
                            salary: 'Telefon raqami',
                            contacts: 'Kontaktlar',
                            tgUsername: 'Telegram username',
                            position: 'Sinf va kurs',
                            actions: '',
                        }}
                        renderItem={(item, index) => (
                            <StudentItem
                                index={index}
                                key={item.id}
                                onUpdate={employees.request}
                                onDelete={employees.request}
                                item={item} />
                        )} />
                </div>
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    column: {
        marginRight: '10px',
    },
    searchBar: {
        '@media (min-width: 900px)': {
            height: '0',
        },
    },
    search: {
        '@media (min-width: 900px)': {
            marginTop: '-4rem',
        },
    },
    button: {
        '@media (min-width: 900px)': {
            marginTop: '-1rem',
        },
    },

    table: {
        '@media (max-width: 500px)': {
            overflowX: 'scroll',
        },
    },
    tableItem: {
        '@media (max-width: 500px)': {
            width: 800,
        },
    },
    br: {
        '@media (max-width: 500px)': {
            width: 800,
        },
    },
})
