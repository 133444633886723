import React, { useState } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { Form, Formik } from 'formik'
import Layout from '../../components/center/Layout'
// import StatisticsAcademySalesChannel from '../../components/center/StatisticsAcademySalesChannel'
// import DateRange from '../../components/common/DateRange'
import { useQueryParams } from '../../hooks/queryString'
import { DATE_FORMAT } from '../../utils/date'
import StatisticsTabs from '../../components/center/StatisticsTabs'
// import StatisticsAcademyCostAdvert from '../../components/center/StatisticsAcademyCostAdvert'
import Card from '../../components/common/Card'
// import StatisticsSalesFunnel from '../../components/center/StatisticsSalesFunnel'
import Select from '../../components/common/Select'
import Date from '../../components/common/Date'
import { useLoad } from '../../hooks/request'
import { MUSTER, SUBJECT_DATE_TIME_LIST, SUBJECT_LIST } from '../../urls'
import Bar from '../../components/dashboard/bar/Bar'
import Loader from '../../components/common/Loader'


export default function StatisticsAcademy() {
    const history = useHistory()
    const params = useQueryParams()
    // const startDate = params.startDate || moment().subtract(1, 'months').format(DATE_FORMAT)
    // const endDate = params.endDate || moment().format(DATE_FORMAT)
    const hour = useLoad({ url: SUBJECT_DATE_TIME_LIST })
    const subject = useLoad({ url: SUBJECT_LIST })
    const date = params.date || moment().subtract(0, 'months').format(DATE_FORMAT)
    const [hourId, setHourId] = useState(1)
    const [subjectId, setSubjectId] = useState(1)
    const muster = useLoad({ url: MUSTER, params: { hour: hourId, date, group_type: 'course' } }, [subjectId])
    return (
        <Layout className="has-background-light">
            <StatisticsTabs />
            <Card>
                <div style={{ gap: 10 }} className="is-flex">
                    <Formik initialValues={{ subject: '', hour: '' }}>
                        {/* {({ setFieldValue }) => ( */}
                        <Form style={{ gap: 10 }} className="is-flex">
                            {/* <Select
                                    onChange={({ target }) => setSubjectId(target.value, setFieldValue)}
                                    name="subject"
                                    options={subject.response ? subject.response : []}
                                    loading={subject.loading}
                                /> */}
                            <Select
                                loading={hour.loading}
                                name="hour"
                                onChange={(e) => setHourId(e.target.value)}
                                options={hour.response ? hour.response : []}
                            />
                        </Form>
                        {/* )} */}
                    </Formik>


                    <Date
                        date={date}
                        onDateChange={(dates) => {
                            history.push(`?${queryString.stringify({ ...params, ...dates })}`)
                        }} />
                </div>
            </Card>
            <br />
            <Card>
                <div className="title is-5 has-text-centered">
                    Davomatlar statistikasi
                </div>
                {!muster.loading ? (
                    <Bar groups={muster.response ? muster.response.groups : []}
                        absentCounts={muster.response ? muster.response.absentCounts : []} />
                )
                    : <Loader center large />}
            </Card>
            <br />
            {/* <Card>
                <DateRange
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={(dates) => {
                        history.push(`?${queryString.stringify({ ...params, ...dates })}`)
                    }} />
            </Card><br />

            <StatisticsAcademySalesChannel
                startDate={startDate}
                endDate={endDate} />

            <StatisticsAcademyCostAdvert
                startDate={startDate}
                endDate={endDate} /><br />

            <StatisticsSalesFunnel
                startDate={startDate}
                endDate={endDate} />
*/}
        </Layout>
    )
}
