import React from 'react'
import { usePostRequest } from '../../hooks/request'
import { TRANSACTION_CATEGORY_LIST } from '../../urls'
import TransactionCategoryForm from './TransactionCategoryForm'

export default function TransactionCategoryCreate({ onCancel, onSuccess }) {
    const transactionCreate = usePostRequest({ url: TRANSACTION_CATEGORY_LIST })

    async function onSubmit(data) {
        const defaultAmount = data.defaultAmount ? data.defaultAmount : null
        await transactionCreate.request({ data: { ...data, defaultAmount } })
        onSuccess()
    }

    return (
        <div>
            <b>Добавить Категорию расходов</b><br /><br />
            <TransactionCategoryForm onSubmit={onSubmit} onCancel={onCancel} loading={transactionCreate.loading} />
        </div>
    )
}
