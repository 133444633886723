import React from 'react'
import { ASSESSMENT_DETAIL } from '../../urls'
import { usePutRequest } from '../../hooks/request'
import ServerError from '../common/ServerError'
import AssessmentForm from './AssessmentForm'

export default function AssessmentUpdate({ onCancel, onUpdate, employee, classId }) {
    const url = ASSESSMENT_DETAIL.replace('{id}', employee.id)
    const employeeUpdate = usePutRequest({ url })

    async function onSubmit(data, actions) {
        const { success } = await employeeUpdate.request({
            data: {
                ...data,
                group: classId,
                teacher: data.teacher.id,
                subject: data.subject.id,
                hour: data.hour.id,
            },
        })

        if (success) {
            actions.resetForm()
            onUpdate()
        }
    }


    return (
        <div>
            <b>Darsni tahrirlash</b><br /><br />
            <ServerError error={employeeUpdate.error} />
            <AssessmentForm
                initialValues={employee}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={employeeUpdate.loading} />
        </div>
    )
}
