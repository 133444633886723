import React from 'react'
import { usePutRequest } from '../../hooks/request'
import { TRANSACTION_DETAIL } from '../../urls'
import TransactionForm from './TransactionForm'

export default function TransactionUpdate({ values, wallet, onSuccess, onCancel }) {
    const url = TRANSACTION_DETAIL.replace('{id}', values.id)
    const transactionUpdate = usePutRequest({ url })

    async function onSubmit(data) {
        await transactionUpdate.request({ data: { ...data, wallet } })
        onSuccess()
    }

    return (
        <div>
            <b>Изменить {values.amount < 0 ? 'расход' : 'приход'}</b><br /><br />

            <TransactionForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                type={values.amount < 0 ? 'outcome' : 'income'}
                loading={transactionUpdate.loading}
                values={{ ...values, amount: Math.abs(values.amount), category: values.category }} />
        </div>
    )
}
