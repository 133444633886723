import React from 'react'
import { usePostRequest } from '../../hooks/request'
import { SALES_CHANNEL_LIST } from '../../urls'
import SalesChannelForm from './SalesChannelForm'

export default function SalesChannelCreate({ onCancel, onSuccess }) {
    const salesChannelCreate = usePostRequest({ url: SALES_CHANNEL_LIST })

    async function onSubmit(data, actions) {
        await salesChannelCreate.request({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Создать канал</b><br /><br />
            <SalesChannelForm onSubmit={onSubmit} onCancel={onCancel} loading={salesChannelCreate.loading} />
        </div>
    )
}
