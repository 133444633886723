import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import { DATE_FORMAT } from '../../utils/date'

export default function Date({ date, onDateChange }) {
    function onChange({ target }) {
        onDateChange({
            date: moment(target.name === 'date' ? target.value : date).format(DATE_FORMAT),
        })
    }

    return (
        <div className={cn('columns is-mobile', css(styles.wrapper))}>
            <div className="column">
                <input
                    name="date"
                    type="date"
                    value={date}
                    className="input"
                    onChange={onChange} />
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '10rem',
    },
    arrow: {
        // margin: '0.2rem 0.6rem 0.4rem 0',
        fontSize: '1.4em',
    },
})
