import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../../utils/validators'
import Input from '../common/Input'
import Button from '../common/Button'

export default function SubjectForm({ onSubmit, onCancel, loading, initialValues }) {
    return (
        <Formik initialValues={{
            name: '',
            ...initialValues,
        }} onSubmit={onSubmit}>
            <Form>
                <Input
                    label="Имя"
                    name="name"
                    disabled={loading}
                    validate={required}
                    placeholder="Введите имя студента" />


                <Button
                    loading={loading}
                    text="Saqlash"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Bekor qilish"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
