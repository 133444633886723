import React, { useState } from 'react'
import { checkPermission as cp } from '../../utils/auth'
import CustomerInfoGroupsForm from './CustomerInfoGroupsForm'
import { useDeleteRequest, usePutRequest } from '../../hooks/request'
import { STUDENT_DETAIL } from '../../urls'
import Loader from '../common/Loader'
import { useMessage } from '../../hooks/message'

export default function CustomerInfoGroupsItem({ customer, item, onDelete, onUpdate }) {
    const url = STUDENT_DETAIL.replace('{id}', item.id)
    const [showMessage] = useMessage()
    const [edit, setEdit] = useState(false)
    const studentDelete = useDeleteRequest({ url })
    const studentUpdate = usePutRequest({ url })

    async function onStudentUpdate(data) {
        const { success, response } = await studentUpdate.request({ data: { ...data, customer: customer.id } })
        const newCustomer = {
            ...customer,
            students: customer.students.map((student) => (
                student.id === response.id ? response : student
            )),
        }

        if (success) {
            onUpdate(newCustomer.id, newCustomer)
        }
    }

    async function removeStudent() {
        if (global.confirm('Haqiqatan ham oʻchirib tashlamoqchimisiz?')) {
            const { error } = await studentDelete.request()

            if (error) {
                showMessage(error.data.detail, 'is-danger')
                return
            }
            const newCustomer = { ...customer, students: customer.students.filter((student) => student !== item) }
            onDelete(newCustomer.id, newCustomer)
        }
    }

    if (edit) {
        return (
            <CustomerInfoGroupsForm
                onCancel={() => setEdit(false)}
                onSubmit={onStudentUpdate}
                customer={customer}
                initValues={item} />
        )
    }

    return (
        <tr>
            <td>{item.group.name}</td>
            <td>{item.price}</td>
            <td>
                {cp('academy.change_student') ? (
                    <i onClick={() => setEdit(true)} className="icon pointer ion-md-create" />
                ) : null}

                {cp('academy.delete_student') && !studentDelete.loading ? (
                    <i onClick={removeStudent} className="icon pointer ion-md-trash" />
                ) : null}

                <Loader show={studentDelete.loading} className="icon" />
            </td>
        </tr>
    )
}
