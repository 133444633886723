import sortBy from 'lodash/sortBy'
import React from 'react'
import Layout from '../../components/center/Layout'
import { useLoad, usePutRequest } from '../../hooks/request'
import { ROOM_LIST, SALES_CHANNEL_LIST, STATUS_LIST, STATUS_MOVE } from '../../urls'
import Table from '../../components/common/Table'
import { checkPermission as cp } from '../../utils/auth'
import Button from '../../components/common/Button'
import { useModal } from '../../hooks/modal'
import ColumnCreate from '../../components/center/ColumnCreate'
import RoomCreate from '../../components/center/RoomCreate'
import ColumnItem from '../../components/center/ColumnItem'
import RoomItem from '../../components/center/RoomItem'
import SettingsTabs from '../../components/center/SettingsTabs'
import Card from '../../components/common/Card'
import SalesChannelItem from '../../components/center/SalesChannelItem'
import SalesChannelCreate from '../../components/center/SalesChannelCreate'


export default function SettingsAcademy() {
    const statuses = useLoad({ url: STATUS_LIST })
    const rooms = useLoad({ url: ROOM_LIST })
    const statusMove = usePutRequest()
    const columns = statuses.response && statuses.response.results ? statuses.response.results : []
    const salesChannels = useLoad({ url: SALES_CHANNEL_LIST }, [])

    const [showSalesChanelCreateModal, hideSalesChanelCreateModal] = useModal(
        <SalesChannelCreate onCancel={() => {
            hideSalesChanelCreateModal()
        }} onSuccess={() => {
            hideSalesChanelCreateModal()
            salesChannels.request()
        }} />,
    )

    const [showColumnCreateModal, hideColumnCreateModal] = useModal(
        <ColumnCreate onSuccess={() => {
            hideColumnCreateModal()
            statuses.request()
        }} onCancel={() => hideColumnCreateModal()} />,
    )

    const [showRoomCreateModal, hideRoomCreateModal] = useModal(
        <RoomCreate onSuccess={async () => {
            await rooms.request()
            hideRoomCreateModal()
        }} onCancel={() => hideRoomCreateModal()} />,
    )

    function changePosition(status, direction) {
        statuses.setResponse({
            results: columns.map((column) => {
                if (column.id === status.id) {
                    return { ...column, position: status.position + direction }
                }
                if (column.position === status.position + direction) {
                    return { ...column, position: status.position }
                }
                return column
            }),
        })
        statusMove.request({
            url: STATUS_MOVE.replace('{id}', status.id),
            data: { position: status.position + direction },
        })
    }

    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <div className="columns">
                <div className="column">
                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Sotish bosqichlari</div>

                            {cp('staff.add_employee') ? (
                                <div className="column is-narrow">
                                    <Button
                                        onClick={showColumnCreateModal}
                                        text="Qo'shish"
                                        icon="ion-md-add"
                                        className="is-pulled-right is-link is-outlined" />
                                </div>
                            ) : null}
                        </div>

                        <Table
                            totalCount={2}
                            loading={statuses.loading}
                            items={sortBy(columns, 'position')}
                            renderItem={(status) => (
                                <ColumnItem
                                    key={status.id}
                                    onDelete={statuses.request}
                                    onUpdate={statuses.request}
                                    status={status}
                                    changePosition={changePosition}
                                    columns={columns} />
                            )} />
                    </Card> <br />

                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Xonalar</div>

                            {cp('academy.add_room') ? (
                                <div className="column is-narrow">
                                    <Button
                                        onClick={showRoomCreateModal}
                                        text="Qo'shish"
                                        icon="ion-md-add"
                                        className="is-pulled-right is-link is-outlined" />
                                </div>
                            ) : null}
                        </div>

                        <Table
                            totalCount={2}
                            loading={rooms.loading}
                            items={rooms.response ? rooms.response.results : []}
                            renderItem={(room) => (
                                <RoomItem
                                    key={room.id}
                                    room={room}
                                    onUpdate={rooms.request}
                                    onDelete={rooms.request} />
                            )} />
                    </Card>
                </div>

                <div className="column">
                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Sotish kanallari</div>
                            {cp('academy.add_advert') ? (
                                <div className="column is-narrow">
                                    <Button
                                        onClick={showSalesChanelCreateModal}
                                        text="Qo'shish"
                                        icon="ion-md-add"
                                        className="is-pulled-right is-link is-outlined" />
                                </div>
                            ) : null}
                        </div>

                        {cp('academy.view_advert') ? (
                            <Table
                                totalCount={2}
                                loading={salesChannels.loading}
                                items={salesChannels.response ? salesChannels.response.results : []}
                                renderItem={(item) => (
                                    <SalesChannelItem
                                        key={item.id}
                                        onUpdate={salesChannels.request}
                                        onDelete={salesChannels.request}
                                        item={item} />
                                )} />
                        ) : null}
                    </Card>
                </div>
            </div>
        </Layout>
    )
}
