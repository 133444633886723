import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string'
import moment from 'moment'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Layout from '../../components/center/Layout'
import { ASSESSMENT_LIST, GROUP_TIMETABLE, SCHOOL_GROUP_DETAIL } from '../../urls'
import { useLoad } from '../../hooks/request'
import Button from '../../components/common/Button'
import { useModal } from '../../hooks/modal'
import AssessmentCreate from '../../components/school/AssessmentCreate'
import Date from '../../components/common/Date'
import { DATE_FORMAT } from '../../utils/date'
import { useQueryParams } from '../../hooks/queryString'
import LessonItem from '../../components/school/LessonItem'
import Loader from '../../components/common/Loader'
import TimeTableGroupItem from '../../components/school/TimeTableGroupItem'

export default function GroupDetail() {
    const { classId } = useParams()
    const params = useQueryParams()
    const classDetail = useLoad({ url: SCHOOL_GROUP_DETAIL.replace('{id}', classId) })
    const [page, setPage] = useState(1)
    const date = params.date || moment().subtract(0, 'months').format(DATE_FORMAT)
    const assessments = useLoad({ url: ASSESSMENT_LIST, params: { group: classId, page, date } }, [page])
    const className = classDetail.response
    const history = useHistory()

    const [showCreateModal, hideCreateModal] = useModal(
        <AssessmentCreate
            groupName={className ? className.name : ''}
            groupId={classId}
            onSuccess={() => {
                assessments.request()
                hideCreateModal()
            }} onCancel={() => hideCreateModal()} />,
    )

    return (
        <Layout>

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <div className="mb-2" style={{ flexBasis: 'auto' }}>
                    <Button
                        onClick={() => history.push('/school')}
                        icon="ion-md-arrow-back"
                        className="is-link is-outlined mr-2" />

                </div>
                <div className="mb-2" style={{ flexBasis: 'auto' }}>
                    <span className="mr-2 tag is-success is-large">Sinf : {className ? className.name : ''}</span>
                </div>

                <div
                    className={cn(css(styles.button), 'mb-2')}
                    style={{ flexBasis: 'auto' }}>
                    <Date
                        date={date}
                        onDateChange={(dates) => {
                            history.push(`?${queryString.stringify({ ...params, ...dates })}`)
                        }} />
                </div>
                <div className="mb-2" style={{ flexBasis: 'auto' }}>
                    <Button
                        onClick={showCreateModal}
                        text="Qo'shish"
                        icon="ion-md-add"
                        className="is-link is-outlined" />
                </div>


            </div>

            <br />
            <div className="columns is-multiline">
                <TimeTableGroupItem date={date} groupId={classId} />

                {!assessments.loading && assessments.response ? assessments.response.results.map((assessment, index) => (
                    <LessonItem
                        index={index}
                        onDelete={assessments.request}
                        onUpdate={assessments.request}
                        assessment={assessment}
                        classId={classId} />
                )) : null}
                <Loader show={assessments.loading} large center />
            </div>

            <div ref={assessments.ref} className="has-text-grey-light is-italic has-text-centered">
                {!assessments.hasMore && !assessments.loading && assessments.length !== 0 ? 'Barcha darslar yuklandi' : ''}
            </div>

        </Layout>
    )
}

const styles = StyleSheet.create({

    assessment: {
        '@media (max-width: 500px)': {
            overflowX: 'scroll',
        },
    },
    assessmentTable: {
        '@media (max-width: 500px)': {
            width: '800px',
        },
    },
})
