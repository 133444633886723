import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import Layout from '../../components/center/Layout'
import { useLoad } from '../../hooks/request'
import { INVOICE_LIST } from '../../urls'
import { useQueryParams } from '../../hooks/queryString'
import Table from '../../components/common/Table'
import InvoiceItem from '../../components/school/InvoiceItem'
import FinanceTabs from '../../components/center/FinanceTabs'
import CustomersFilter from '../../components/center/CustomersFilter'


export default function InvoiceComplete() {
    const params = useQueryParams()
    const history = useHistory()
    const [page, setPage] = useState(1)
    const employees = useLoad({
        url: INVOICE_LIST,
        params: { ...params, page, status: 'canceled', borrow: true },
    }, [page, params])


    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ search })}`)
    }


    return (
        <Layout>
            <FinanceTabs />

            <div className={cn('columns', css(styles.searchBar))}>
                <div className="column is-4 is-offset-8">
                    <CustomersFilter className={css(styles.search)} />
                </div>
            </div>

            <div className={css(styles.table)}>
                <div className={css(styles.tableItem)}>
                    <Table
                        activePage={page}
                        onPageChange={setPage}
                        loading={employees.loading}
                        totalCount={employees.response ? employees.response.count : 0}
                        items={employees.response ? employees.response.results : []}
                        columns={{
                            name: 'Ism',
                            birthday: 'Maktab yoki kurs',
                            salary: 'Qarz summasi',
                            contacts: 'Oy',
                            position: 'Status',
                        }}
                        renderItem={(item) => (
                            <InvoiceItem key={item.id} onUpdate={employees.request} onDelete={employees.request}
                                item={item} />
                        )} />
                </div>
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    column: {
        marginRight: '10px',
    },
    searchBar: {
        '@media (min-width: 900px)': {
            height: '0',
        },
    },
    search: {
        '@media (min-width: 900px)': {
            marginTop: '-4rem',
        },
    },
    table: {
        '@media (max-width: 500px)': {
            overflowX: 'scroll',
        },
    },
    tableItem: {
        '@media (max-width: 500px)': {
            width: 800,
        },
    },
})
