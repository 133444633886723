/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Formik, Form } from 'formik'
import { required, email } from '../../utils/validators'
import { useLoad } from '../../hooks/request'
import { checkPermission as cp } from '../../utils/auth'
import { SALES_CHANNEL_LIST, EMPLOYEE_SIMPLE_LIST } from '../../urls'
import Button from '../common/Button'
import Input from '../common/Input'
import Select from '../common/Select'
import Phone from '../common/Phone'


export default function CustomerForm({ loading, onSubmit, onCancel, values }) {
    const [advanced, setAdvanced] = useState(false)
    const description = values && values.description ? values.description : ''
    const seller = values && values.seller ? values.seller : ''
    const initialValues = {
        name: '',
        phone: '',
        phoneSecond: '',
        advert: '',
        ...values,
        email: values && values.email ? values.email : '',
        seller,
        description,
    }
    const salesChannel = useLoad({ url: SALES_CHANNEL_LIST })
    const employee = useLoad({ url: EMPLOYEE_SIMPLE_LIST })

    return (
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
            <Form>
                <Input name="name" label="Имя" validate={required} autoFocus autoComplete="off" />

                <Select
                    name="advert"
                    options={salesChannel.response ? salesChannel.response.results : []}
                    label="Канал продаж"
                    help="Добавить канал продаж можно в настройках"
                    loading={salesChannel.loading}
                    empty={values ? values.salesChannel : false}
                    validate={required} />

                <Phone />

                <Input
                    optional
                    name="email"
                    type="email"
                    label="Емайл адрес"
                    validate={email} />

                <Input name="description" component="textarea" label="Примечание" optional />

                <a onClick={() => setAdvanced(!advanced)}>
                    Расширенная форма
                </a><br />

                {advanced ? (
                    <div className={css(styles.advanced)}>
                        {cp('academy.add_customer_seller') ? (
                            <Select
                                name="seller"
                                options={employee.response ? employee.response.results : []}
                                label="Менеджер продаж"
                                help="Вы можете добавить нового менеджер продаж в меню персонал"
                                empty={values ? values.seller : false}
                                loading={employee.loading} />
                        ) : null}
                    </div>
                ) : null}<br />

                <Button
                    loading={loading}
                    text="Saqlash"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Bekor qilish"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}

const styles = StyleSheet.create({
    advanced: {
        background: 'rgba(251, 248, 248, 1)',
        borderRadius: '.3rem',
        padding: '1rem',
    },
})
