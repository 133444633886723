import { Form, Formik } from 'formik'
import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { required } from '../../utils/validators'
import Button from '../common/Button'
import { useLoad } from '../../hooks/request'
import { EMPLOYEE_SIMPLE_LIST, SUBJECT_DATE_TIME_LIST, SUBJECT_LIST } from '../../urls'
import CustomSelect from '../common/CustomSelect'

export default function AssessmentForm({ onSubmit, onCancel, loading, initialValues }) {
    const hour = useLoad({ url: SUBJECT_DATE_TIME_LIST })
    const subject = useLoad({ url: SUBJECT_LIST })
    const employee = useLoad({ url: EMPLOYEE_SIMPLE_LIST, params: { position: 'teacher' } })


    const employeeItem = employee.response ? employee.response.results : []
    const employeeOptions = Object.entries(employeeItem).map(([, {
        id,
        name,
    }]) => ({
        id,
        name: id,
        label: name,
    }))
    const subjectItem = subject.response ? subject.response : []
    const subjectOptions = Object.entries(subjectItem).map(([, {
        id,
        name,
    }]) => ({
        id,
        name: id,
        label: name,
    }))
    const hourItem = hour.response ? hour.response : []
    const hourItemOptions = Object.entries(hourItem).map(([, {
        id,
        name,
    }]) => ({
        id,
        name: id,
        label: name,
    }))
    return (
        <Formik initialValues={{
            ...initialValues,
            teacher: initialValues && !isEmpty(initialValues.teacher) ? {
                label: initialValues.teacher.name,
                name: initialValues.teacher.id,
                id: initialValues.teacher.id,
            } : '',
            subject: initialValues && !isEmpty(initialValues.subject) ? {
                label: initialValues.subject.name,
                name: initialValues.subject.id,
                id: initialValues.subject.id,
            } : '',
            hour: initialValues && !isEmpty(initialValues.hour) ? {
                label: initialValues.hour.name,
                name: initialValues.hour.id,
                id: initialValues.hour.id,
            } : '',
        }} onSubmit={onSubmit}>
            <Form>
                <CustomSelect
                    name="teacher"
                    options={employeeOptions}
                    label="O'qituvchining ismi"
                    loading={employee.loading}
                    validate={required} />

                <CustomSelect
                    name="hour"
                    options={hourItemOptions}
                    label="Vaqt"
                    loading={hour.loading}
                    validate={required} />

                <CustomSelect
                    name="subject"
                    options={subjectOptions}
                    label="Dars"
                    loading={subject.loading}
                    validate={required} />


                <Button
                    loading={loading}
                    text="Saqlash"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Bekor qilish"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
