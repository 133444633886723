import React from 'react'
import { SUBJECT_DATE_TIME_DETAIL } from '../../urls'
import { usePutRequest } from '../../hooks/request'
import ServerError from '../common/ServerError'
import HourForm from './HourForm'

export default function HourUpdate({ onCancel, onUpdate, employee }) {
    const url = SUBJECT_DATE_TIME_DETAIL.replace('{id}', employee.id)
    const employeeUpdate = usePutRequest({ url })

    async function onSubmit(data, actions) {
        const { success } = await employeeUpdate.request({
            data,
        })

        if (success) {
            actions.resetForm()
            onUpdate()
        }
    }


    return (
        <div>
            <b>Saotni tahrirlash</b><br /><br />
            <ServerError error={employeeUpdate.error} />
            <HourForm
                initialValues={employee}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={employeeUpdate.loading} />
        </div>
    )
}
