import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { format } from '../../utils/number'


export default function TimeTableAcademyItem({ wallet }) {
    return (
        <div className="column is-4-widescreen is-6-desktop is-12-tablet">
            <div className={cn(css(styles.height), 'box')}>
                <div className="columns">
                    <div style={{ marginTop: '-1.5rem' }} className="column">
                        <Link to={`/timetable/group/${wallet.id}`}>
                            <div className="column is-flex is-justify-content-center">
                                <div className={cn('icon has-text-grey-light', css(styles.icon))}>{wallet.name}</div>
                            </div>
                        </Link>
                        <b>{'Kurs rahbari'
                            || <span className="is-italic has-text-grey">ko'rsatilmagan</span>}: {wallet.teacher.name}
                        </b>
                        <p>
                            O'quvchilar soni: &nbsp;
                            <span className="has-text-info">
                                {format(wallet.studentCount || 0)} ta o'quvchi
                            </span>
                        </p>

                    </div>
                </div>
            </div>
        </div>
    )
}


const styles = StyleSheet.create({
    icon: {
        width: '100%',
        fontSize: '1.5em',
        margin: '2rem 1rem',
    },
    height: {
        height: '100%',
    },
    buttonAction: {
        '@media (max-width: 500px)': {
            display: 'flex',
            marginTop: 10,
            justifyContent: 'center',
        },
    },
})
