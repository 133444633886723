import React, { Fragment } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import Table from '../common/Table'
import Loader from '../common/Loader'
import CustomerTableItem from './CustomerTableItem'
import Button from '../common/Button'
import { useModal } from '../../hooks/modal'
import CustomerCreate from './CustomerCreate'


export default function CustomerTable({ statuses, statsObj, onUpdate, customers, type }) {
    const [showCreateModal, hideCreateModal] = useModal(
        <CustomerCreate
            onCancel={() => hideCreateModal()}
            onSuccess={() => {
                customers.reload()
                hideCreateModal()
            }} />,
    )

    return (
        <Fragment>
            <Table
                showEmptyMessage={!customers.loading}
                columns={{
                    name: 'Имя',
                    status: 'Состояние',
                    groups: 'Группы',
                    actions: <Button
                        text="Qo'shish"
                        onClick={showCreateModal}
                        className={cn('is-outlined is-info is-small is-pulled-right', css(styles.createButton))}
                        icon="ion-md-add" />,
                }}
                items={customers.response ? customers.response.results : []}
                renderItem={(item) => (
                    <CustomerTableItem
                        key={item.id}
                        customer={item}
                        onDelete={customers.reload}
                        onUpdate={onUpdate}
                        status={statsObj[item.state]}
                        showLabels={type === ''}
                        statusList={statuses}
                        type={type} />
                )} />

            <Loader show={customers.loading} center large />

            <div ref={customers.ref} className="has-text-grey-light is-italic has-text-centered">
                {!customers.hasMore && !customers.loading && customers.length !== 0 ? 'Загрузили всех студентов' : ''}
            </div>
        </Fragment>
    )
}

const styles = StyleSheet.create({
    createButton: {
        marginRight: '-0.75rem',
    },
})
