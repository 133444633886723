import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React from 'react'
import { position, positionClass } from '../../utils/position'

export default function EmployeePositions({ positions }) {
    return positions.map((item) => (
        <span key={item} className={cn('tag', positionClass(item), css(styles.tag))}>
            {position(item)}
        </span>
    ))
}

const styles = StyleSheet.create({
    tag: {
        marginRight: '3px',
    },
})
