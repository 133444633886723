import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { FieldArray, Form, Formik } from 'formik'
import Layout from '../../components/center/Layout'
import { ASSESSMENT_DETAIL, ASSESSMENT_ITEM_LIST, ASSESSMENT_UPDATE } from '../../urls'
import { useLoad, usePutRequest } from '../../hooks/request'
import Button from '../../components/common/Button'
import Input from '../../components/common/Input'
import Checkbox from '../../components/common/Checkbox'
import { css, StyleSheet } from "aphrodite";
import { marks, tests } from "../../utils/position";
import Select from "../../components/common/Select";
import { useMessage } from "../../hooks/message";
import { getDate } from "../../utils/date";
import Loader from "../../components/common/Loader";
import cn from "classnames";
import ServerErrorCustom from "../../components/common/ServerErrorCustom";


export default function AssessmentDetail() {
    const { assessmentId, classId } = useParams()
    const classDetail = useLoad({ url: ASSESSMENT_DETAIL.replace('{id}', assessmentId) })
    const assessmentItems = useLoad({ url: ASSESSMENT_ITEM_LIST, params: { assessment: assessmentId, size: 40 } },)
    const className = classDetail.response
    console.log(classDetail.response ? classDetail.response : {})
    const history = useHistory()
    const [showMessage] = useMessage()

    const assessmentsUpdate = usePutRequest({ url: ASSESSMENT_UPDATE.replace('{id}', assessmentId) })

    async function onSubmit(values, actions) {
        const { success } = await assessmentsUpdate.request({ data: values });
        await onCreate(values)
        if (success) {
            await classDetail.request()
            // Fetch the updated assessment items
            const newAssessmentItems = await assessmentItems.request();
            if (newAssessmentItems.response) {
                // Update the form values
                actions.setValues({
                    students: newAssessmentItems.response.results.map(i => ({
                        name: i.student.name,
                        student: i.student.id,
                        mark: i.mark,
                        testScore: i.testScore,
                        homeworkStatus: i.homeworkStatus,
                        assessment: i.assessment,
                        isAbsent: i.isAbsent,
                        comment: i.comment,
                    }))
                });
            }

            showMessage('Ma\'lumotlar muvofaqiyatli o\'zgartirildi.', 'is-success');
            actions.resetForm();
        }
    }

    async function onCreate(data) {
        await assessmentsUpdate.request({ data });
    }

    const studentsInitialValues = assessmentItems.response
        ? assessmentItems.response.results.map(i => ({
            name: i.student.name,
            student: i.student.id,
            mark: i.mark,
            testScore: i.testScore,
            homeworkStatus: i.homeworkStatus,
            assessment: i.assessment,
            isAbsent: i.isAbsent,
            comment: i.comment,
        }))
        : [];
    const options = Object.entries(tests).map(([id, name]) => ({ id, name }))
    const mark = Object.entries(marks).map(([id, name]) => ({ id, name }))
    return (
        <Layout>
            <div className="columns mt-2">
                <Button
                    onClick={() => history.push(`/class/${classId}`)}
                    icon="ion-md-arrow-back"
                    className="is-link is-outlined mr-2"/>
                <span
                    className="mr-2 tag is-success is-large is-light has-text-black mb-1">Dars: {className ? className.subject.name : ''}
            </span>
                <span
                    className="tag is-danger mr-2 is-large is-light has-text-black mb-1">Saot: {className ? className.hour.name : ''}
            </span>
                <span
                    className="tag is-warning mr-2 is-large is-light has-text-black mb-1">O'qituvchi: {className ? className.teacher.name : ''}
            </span>
                <span
                    className="tag is-link mr-2 is-large is-light has-text-black mb-1">Sana: {className ? getDate(className.createdAt) : ''}
            </span>
            </div>
            <ServerErrorCustom error={assessmentsUpdate.error} />
            <div className={css(styles.assessmentForm)}>
                <div className={css(styles.assessmentFormDiv)}>
                    {!assessmentItems.loading ? <> {assessmentItems?.response?.results && (
                        <Formik
                            initialValues={{
                                students: studentsInitialValues,
                                testStatus: classDetail.response ? classDetail.response.testStatus : false,
                                testCount: classDetail.response ? classDetail.response.testCount : 0,
                            }}
                            onSubmit={onSubmit}>
                            {({ values }) => (
                                <Form>
                                    <span
                                        className="tag is-link mr-2 is-large is-light has-text-black">
                                        <div className="mt-2">
                                            <Checkbox
                                                className="mt-3"
                                                label="Test sonini kiriting"
                                                name="testStatus"
                                                type="checkbox"
                                                style={{ transform: 'scale(1.5)' }}
                                            />
                                        </div>
                                        <Input
                                            type="number"
                                            disabled={!values.testStatus}
                                            name="testCount"
                                            className="is-small"/>
                                    </span>
                                    <table className='table is-striped is-fullwidth'>
                                        <tbody>
                                        <tr>
                                            <th>№</th>
                                            <th>O'quvchi ismi</th>
                                            <th>Yoqlama</th>
                                            <th>Uyga vazifa</th>
                                            <th>Baholash</th>
                                            {values.testStatus ? <th>Test natijasi</th> : null}
                                            <th>Izoh</th>

                                        </tr>
                                        <FieldArray name="students">
                                            {() => (
                                                values.students.map((student, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <span>{index + 1}</span>
                                                        </td>
                                                        <td>
                    <span
                        className={cn(
                            values.students[index].homeworkStatus && values.students[index].mark ? 'has-text-black' : 'has-text-danger'
                        )}
                    >
                        {student.name}
                    </span>
                                                        </td>
                                                        <td>
                                                            <Checkbox
                                                                name={`students[${index}].isAbsent`}
                                                                type="checkbox"
                                                                style={{ transform: 'scale(1.5)' }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Select
                                                                disabled={!student.isAbsent}
                                                                options={options}
                                                                className="is-small"
                                                                name={`students[${index}].homeworkStatus`}
                                                                empty
                                                            />
                                                        </td>
                                                        <td>
                                                            <Select
                                                                disabled={!student.isAbsent}
                                                                options={mark}
                                                                className="is-small"
                                                                name={`students[${index}].mark`}
                                                                empty
                                                            />
                                                        </td>
                                                        {values.testStatus ? <td>
                                                            <Input
                                                                disabled={!student.isAbsent}
                                                                className="is-small"
                                                                name={`students[${index}].testScore`}
                                                                placeholder="Sinov natijasini kiriting"
                                                                type="text"
                                                            />
                                                        </td> : null}
                                                        <td>
                                                            <Input
                                                                disabled={!student.isAbsent}
                                                                className="is-small"
                                                                name={`students[${index}].comment`}
                                                                placeholder="Izoh kiriting ..."
                                                                type="text"
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </FieldArray>
                                        </tbody>
                                    </table>
                                    {assessmentItems.response && assessmentItems.response.results.length > 0 ? (
                                        <Button
                                            loading={assessmentsUpdate.loading}
                                            type="submit"
                                            icon="ion-md-paper-plane"
                                            text="Saqlash"
                                            className="is-link is-outlined is-pulled-right mb-3"/>
                                    ) : null}
                                </Form>
                            )}
                        </Formik>
                    )}</> : <Loader center large/>}
                </div>
            </div>

        </Layout>
    )
}


const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
    assessmentForm: {
        '@media (max-width: 500px)': {
            overflowX: 'scroll',
        },
    },
    assessmentFormDiv: {
        '@media (max-width: 500px)': {
            width: '800px'
        },
    },
})
