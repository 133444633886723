import React from 'react'
import map from 'lodash/map'
import { usePostRequest } from '../../hooks/request'
import ServerError from '../common/ServerError'
import { INVOICE_LIST } from '../../urls'
import InvoiceForm from './InvoiceForm'

export default function InvoiceCreate({ onCancel, onSuccess }) {
    const studentCreate = usePostRequest({ url: INVOICE_LIST })

    async function onSubmit(data, actions) {
        const { success } = await studentCreate.request({
            data: {
                ...data, group: map(data.group, 'value'), student: data.student.id,
            },
        })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <b>Yangi to'lovni qo'shing</b><br /><br />
            <ServerError error={studentCreate.error} />
            <InvoiceForm onSubmit={onSubmit} onCancel={onCancel} loading={studentCreate.loading} />
        </div>
    )
}
